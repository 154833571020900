import BaseAPI from './base';
import { formatQueryData } from './helpers';

class NotificationAPI extends BaseAPI {
  async getNotifications(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('notification', {
      ...newQueryData,
    });

    return response;
  }

  async openNotification(notificationId) {
    const url = `${this.jsonApi.urlFor({
      model: 'notification',
      id: notificationId,
    })}/open`;

    return this.jsonApi.customRequest('POST', url, 'notification');
  }
}

export default NotificationAPI;
