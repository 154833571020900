import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  TextField as MuiTextField,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningTwoTone';
import TooltipLabel from '../TooltipLabel';

import './TextField.scss';

const TextField = (props) => {
  const {
    tooltip,
    warningMessage,
    label,
    unit,
    required,
    validate,
    errorMessage,
    onBlur,
  } = props;

  const [isValid, setIsValid] = useState(true);

  let textFieldProps = { ...props };

  delete textFieldProps.unit;
  delete textFieldProps.required;
  delete textFieldProps.validate;
  delete textFieldProps.errorMessage;
  delete textFieldProps.warningMessage;

  const onBlurValidation = (event) => {
    const {
      target: { value },
    } = event;

    setIsValid(validate(value));

    if (onBlur) {
      onBlur(event);
    }
  };

  if (label) {
    let labelClassName = 'MRets__TextField__Label';
    if (tooltip || warningMessage) {
      textFieldProps = {
        ...textFieldProps,
        InputLabelProps: { style: { pointerEvents: 'auto' } },
      };

      labelClassName = classnames('MRets__TextField__Label', {
        'MRets__TextField__Label--tooltip': tooltip,
        'MRets__TextField__Label--warningMessage': warningMessage,
        'MRets__TextField__Label--tooltip-and-warningMessage':
          tooltip && warningMessage,
      });
    }

    textFieldProps = {
      ...textFieldProps,
      label: (
        <InputLabel
          shrink
          style={{ pointerEvents: 'auto' }}
          component="div"
          className={labelClassName}
        >
          {!tooltip && label}
          {!!tooltip && <TooltipLabel tooltip={tooltip} label={label} />}
          {required && <span className="MRets__TextField__Required"> *</span>}
          {!!warningMessage && (
            <TooltipLabel
              className="MRets__TextField__WarningMessage"
              tooltip={warningMessage}
              icon={WarningIcon}
            />
          )}
        </InputLabel>
      ),
    };
  }

  if (unit) {
    textFieldProps.InputProps = {
      endAdornment: (
        <InputAdornment
          className="MRets__TextField__Adornment MRets__TextField__EndAdornment"
          position="end"
        >
          {unit}
        </InputAdornment>
      ),
    };
  }

  if (validate) {
    textFieldProps.onBlur = onBlurValidation;
  }

  if (!isValid) {
    textFieldProps.error = true;
    textFieldProps.helperText = errorMessage || textFieldProps.helperText || '';
  }

  const textFieldClassName = classnames('MRets__TextField', {
    'MRets__TextField--tooltip': tooltip,
    'MRets__TextField--warningMessage': warningMessage,
    'MRets__TextField--tooltip-and-warningMessage': tooltip && warningMessage,
  });

  return (
    <MuiTextField
      className={textFieldClassName}
      variant="outlined"
      fullWidth
      {...textFieldProps}
    />
  );
};

TextField.defaultProps = {
  tooltip: null,
  warningMessage: null,
  label: null,
  unit: null,
  required: false,
  errorMessage: '',
  validate: null,
  onBlur: null,
};

TextField.propTypes = {
  tooltip: PropTypes.string,
  warningMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  unit: PropTypes.any,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  validate: PropTypes.func,
  onBlur: PropTypes.func,
};

export default TextField;
