import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ButtonBase } from '@mui/material';
import { SUBNAV_STATUSES_ICONS } from './SubNav.constants';

import './SubNav.scss';

const SubNav = ({
  navItems,
  path,
  value,
  children,
  usePathPrefix,
  onChange,
}) => {
  const TabComponent = onChange ? ButtonBase : NavLink;

  return (
    <div className="SubNav">
      <ul className="SubNav__List">
        {navItems.map((navItem) => {
          const IconComponent =
            navItem.icon ||
            (navItem.status && SUBNAV_STATUSES_ICONS[navItem.status]);

          const className = classnames(
            `SubNav__LisItem SubNav__LisItem--${navItem.status || 'default'}`,
            {
              'SubNav__ListItem--with-icon': IconComponent,
              'SubNav__ListItem--active': navItem.value === value,
            },
          );

          let tabComponentProps = {
            to: `${usePathPrefix ? path : ''}${navItem.path}`,
            activeClassName: 'active',
          };

          if (onChange) {
            tabComponentProps = {
              onClick: onChange(navItem),
            };
          }

          return (
            <li key={navItem.path || navItem.value} className={className}>
              <TabComponent className="SubNav__Tab" {...tabComponentProps}>
                {!!IconComponent && (
                  <div className="SubNav__Tab__Icon">
                    <IconComponent />
                  </div>
                )}
                <div className="SubNav__Tab__Label">{navItem.label}</div>
              </TabComponent>
            </li>
          );
        })}
      </ul>
      {!!children && <div className="SubNav__Children">{children}</div>}
    </div>
  );
};

SubNav.defaultProps = {
  usePathPrefix: true,
  value: '',
  onChange: null,
  children: null,
};

SubNav.propTypes = {
  navItems: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  value: PropTypes.string,
  usePathPrefix: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

export default SubNav;
