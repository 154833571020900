import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Checkbox, FormControlLabel } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningTwoTone';

import './CheckboxField.scss';
import TooltipLabel from '../TooltipLabel';

const CheckboxField = ({
  label,
  disabled,
  onChange,
  options,
  required,
  values,
  error,
  flat,
  warningMessage,
}) => {
  const className = classnames('MRets__CheckboxField', {
    'MRets__CheckboxField--error': error,
    'MRets__CheckboxField--warning': warningMessage,
  });

  return (
    <div className={className}>
      {!!label && (
        <h5 className="MRets__CheckboxField__Label">
          {label}
          {required && (
            <span className="MRets__CheckboxField__Required"> *</span>
          )}
          {!!warningMessage && (
            <TooltipLabel
              className="MRets__CheckboxField__WarningMessage"
              tooltip={warningMessage}
              icon={WarningIcon}
            />
          )}
        </h5>
      )}
      <div className="MRets__CheckboxField__Options">
        {options.map((option) => {
          const optionValue = flat ? option : option.value;
          const optionLabel = flat ? option : option.label;

          return (
            <FormControlLabel
              key={optionValue}
              className="MRets__CheckboxField__Option"
              control={
                <Checkbox
                  checked={values.indexOf(optionValue) >= 0}
                  onChange={onChange}
                  value={optionValue}
                  disabled={disabled}
                  color="primary"
                />
              }
              label={optionLabel}
            />
          );
        })}
      </div>
    </div>
  );
};

CheckboxField.defaultProps = {
  disabled: false,
  error: false,
  flat: false,
  required: false,
  values: [],
  label: undefined,
  warningMessage: null,
};

CheckboxField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  flat: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ).isRequired,
  warningMessage: PropTypes.string,
};

export default CheckboxField;
