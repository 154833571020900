import React, { useEffect, useState } from 'react';
import PersistentNotification from './PersistentNotification.component';
import { PERSISTENT_NOTIFICATIONS } from './PersistentNotification.constants';
import api from '../../../../services/api';

const PersistentNotificationContainer = (props) => {
  const [notification, setNotification] = useState(null);

  const getPersistentNotification = async () => {
    const queryData = {
      filters: {
        show_persistent: true,
      },
      sortBy: { created_at: 'desc' },
    };

    const response = await api.notification.getNotifications(queryData);

    if (response.data?.length) {
      const notificationObj = response.data[0];
      setNotification({
        ...PERSISTENT_NOTIFICATIONS.billing,
        ...notificationObj,
      });
    }
  };

  useEffect(() => {
    getPersistentNotification();
  }, []);

  if (!notification) {
    return null;
  }

  return <PersistentNotification {...notification} {...props} />;
};

export default PersistentNotificationContainer;
