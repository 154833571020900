import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../../Spinner';
import Modal from '../Modal';

import './LoadingModal.css';

const LoadingModal = ({ open, modalText }) => {
  return (
    <Modal open={open} hideCloseButton onClose={() => {}}>
      <div className="LoadingModal__Loading">
        <Spinner />
        <div className="LoadingModal__LoadingText">{modalText}</div>
      </div>
    </Modal>
  );
};

LoadingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  modalText: PropTypes.string.isRequired,
};

export default LoadingModal;
