import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

const SelectedOptions = ({
  selectedOptions,
  valueKey,
  labelKey,
  fallbackLabelKey,
  onSelectedOptionDelete,
}) => {
  const onRemoveOption = (option) => () => {
    onSelectedOptionDelete(option);
  };

  return (
    <div>
      {selectedOptions.map((option) => (
        <Chip
          className="TableHeader__Chips TableHeader__Chip"
          variant="outlined"
          size="small"
          color="primary"
          key={option[valueKey]}
          label={option[labelKey] || option[fallbackLabelKey]}
          onDelete={onRemoveOption(option)}
        />
      ))}
    </div>
  );
};

SelectedOptions.defaultProps = {
  valueKey: 'id',
  labelKey: 'name',
  fallbackLabelKey: '',
  onSelectedOptionDelete: () => {},
};

SelectedOptions.propTypes = {
  selectedOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  fallbackLabelKey: PropTypes.string,
  onSelectedOptionDelete: PropTypes.func,
};

export default SelectedOptions;
