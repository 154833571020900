import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button from '../Button';

import './ListBox.scss';

const ListBox = ({ title, items, actions, children, className }) => {
  const listboxClassName = classnames('ListBox', className, {
    'ListBox--no-title': !title,
    'ListBox--no-items': !items.length,
    'ListBox--no-actions': !actions.length,
    'ListBox--with-children': !!children,
  });

  return (
    <div className={listboxClassName}>
      {!!title && (
        <div className="ListBox__Header">
          <div className="ListBox__Header__Title">{title}</div>
          <div className="ListBox__Header__Actions">
            {actions.map(({ name, callback }) => (
              <Button
                key={name}
                size="small"
                className="mr1"
                variant="secondary"
                onClick={callback}
              >
                {name}
              </Button>
            ))}
          </div>
        </div>
      )}
      <div className="ListBox__Content">
        {!!children && (
          <div className="ListBox__ContentChildren">{children}</div>
        )}
        <ul className="ListBox__ContentList">
          {items.map(
            ({ name, value, color, className: itemClassName = '' }) => (
              <li
                key={name}
                className={classnames(
                  'ListBox__ContentList__Item',
                  itemClassName,
                )}
              >
                <div className="ListBox__Item__Label">
                  {!!color && (
                    <span
                      className="ListBox__Item__Label__Color"
                      style={{ backgroundColor: color }}
                    />
                  )}
                  {name}
                </div>
                <div className="ListBox__Item__Value">{value}</div>
              </li>
            ),
          )}
        </ul>
      </div>
    </div>
  );
};

ListBox.defaultProps = {
  title: null,
  children: null,
  actions: [],
  className: 'ListBox--default',
};

ListBox.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      callback: PropTypes.func,
    }),
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
    }),
  ).isRequired,
  className: PropTypes.string,
};

export default ListBox;
