import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PersistentNotification from './PersistentNotification';
import { Permissions } from '../../shared';
import { REGIONS } from '../../../constants';

const Content = ({ constrained, children, open }) => {
  return (
    <main
      role="main"
      className={classNames(
        'fixed flex flex-column right-0 bottom-0 top-3 overflow-auto translateZ0 transition-left bg-light-gray',
        {
          'left-14_375': open,
          'left-0': !open,
        },
      )}
    >
      <Permissions
        region={REGIONS.mrets}
        featureFlag="persistent-notifications"
      >
        <PersistentNotification />
      </Permissions>

      <div className="flex-auto-1-1 flex flex-column pa3">
        <div
          className={classNames(
            'br2 flex-auto-1-0 flex flex-column center w-100 relative',
            {
              maxw8: constrained,
            },
          )}
        >
          {children}
        </div>
      </div>
    </main>
  );
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
  constrained: PropTypes.bool,
  open: PropTypes.bool,
};

Content.defaultProps = {
  constrained: false,
  open: false,
};

export default Content;
