import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import classnames from 'classnames';

import TextField from '../TextField';
import './MultipleSelectField.scss';
import SelectedOptions from './SelectedOptions.component';

const MultipleSelectField = ({
  options,
  onChange,
  valueKey,
  secondaryValueKey,
  labelKey,
  selectedOptions,
  selectedOption,
  placeholder,
  label,
  required,
  showOptions,
  showOptionsAtRight,
  multiple,
  filterOptions,
  helperText,
  ...otherProps
}) => {
  const onSelectedOptionDelete = (deletedOption) => {
    const newSelectedOptions = selectedOptions.filter(
      (so) => so[valueKey] !== deletedOption[valueKey],
    );
    onChange({ target: { value: newSelectedOptions } });
  };

  const onSelectedOptionsChange = (event, values) => {
    onChange({ target: { value: values } });
  };

  const onSelectedOptionsChecksChange = (selected, option) => () => {
    let newSelectedOption;

    if (multiple) {
      if (selected) {
        newSelectedOption = selectedOptions.filter(
          (so) => so[valueKey] !== option[valueKey],
        );
      } else {
        newSelectedOption = selectedOptions;
        newSelectedOption.push(option);
      }
    } else {
      newSelectedOption = option;
    }

    onChange({ target: { value: newSelectedOption } });
  };

  const buildPlaceholder = () => {
    let newPlaceholder = placeholder;
    if (!multiple && selectedOption) {
      newPlaceholder = selectedOption[labelKey];
    }
    if (multiple && selectedOptions.length > 0) {
      newPlaceholder = `${selectedOptions.length} selected`;
    }
    return newPlaceholder;
  };

  return (
    <div
      className={classnames('MultipleSelectField', {
        'MultipleSelectField--row': showOptionsAtRight,
        'MultipleSelectField--column': !showOptionsAtRight,
      })}
    >
      <FormControl className="MultipleSelectField__Select" variant="outlined">
        {!!label && (
          <InputLabel shrink style={{ pointerEvents: 'auto' }}>
            <span>{label}</span>
            {required && (
              <span className="MRets__MultipleSelectField__Required"> *</span>
            )}
          </InputLabel>
        )}
        <Autocomplete
          multiple={multiple}
          onChange={onSelectedOptionsChange}
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) => option[labelKey] || ''}
          isOptionEqualToValue={(option, value) => {
            return option[valueKey] === value[valueKey];
          }}
          filterOptions={createFilterOptions(filterOptions)}
          value={multiple ? selectedOptions : selectedOption}
          renderOption={(props, option, { selected }) => (
            <li
              className="MultipleSelectField__Option"
              key={`${option[valueKey]}${
                secondaryValueKey ? option[secondaryValueKey] : ''
              }`}
              value={option[valueKey]}
            >
              {multiple && (
                <Checkbox
                  checked={selected}
                  onChange={onSelectedOptionsChecksChange(selected, option)}
                  color="secondary"
                />
              )}
              {!multiple && (
                <Radio
                  checked={selected}
                  onChange={onSelectedOptionsChecksChange(selected, option)}
                  color="secondary"
                />
              )}
              <span className="Option__Text">
                <ListItemText primary={option[labelKey]} />
              </span>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: null,
              }}
              placeholder={buildPlaceholder()}
            />
          )}
          {...otherProps}
        />
        {!!helperText && (
          <div className="MultipleSelectField__HelpText">{helperText}</div>
        )}
      </FormControl>
      {showOptions && multiple && (
        <div className="MultipleSelectField__SelectedOptions">
          <SelectedOptions
            selectedOptions={selectedOptions}
            valueKey={valueKey}
            labelKey={labelKey}
            onSelectedOptionDelete={onSelectedOptionDelete}
          />
        </div>
      )}
    </div>
  );
};

MultipleSelectField.defaultProps = {
  placeholder: 'Select...',
  valueKey: 'id',
  secondaryValueKey: 'null',
  labelKey: 'name',
  label: null,
  required: false,
  onChange: () => {},
  showOptionsAtRight: true,
  showOptions: true,
  multiple: true,
  filterOptions: {},
  helperText: null,
  selectedOptions: [],
  selectedOption: null,
};

MultipleSelectField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  valueKey: PropTypes.string,
  secondaryValueKey: PropTypes.string,
  labelKey: PropTypes.string,
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  selectedOption: PropTypes.object,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  showOptionsAtRight: PropTypes.bool,
  showOptions: PropTypes.bool,
  multiple: PropTypes.bool,
  filterOptions: PropTypes.object,
  helperText: PropTypes.string,
};

export default MultipleSelectField;
