const UPDATE_SEARCH_VALUE = 'SCROLLABLE_SELECT_FIELD/UPDATE_SEARCH_VALUE';
const UPDATE_DISPLAY_VALUE = 'SCROLLABLE_SELECT_FIELD/UPDATE_DISPLAY_VALUE';
const UPDATE_ITEMS = 'SCROLLABLE_SELECT_FIELD/UPDATE_ITEMS';
const UPDATE_TOTAL_PAGES = 'SCROLLABLE_SELECT_FIELD/UPDATE_TOTAL_PAGES';
const UPDATE_CURRENT_PAGE = 'SCROLLABLE_SELECT_FIELD/UPDATE_CURRENT_PAGE';

export const updateSearchValueAction = (payload) => ({
  type: UPDATE_SEARCH_VALUE,
  payload,
});
export const updateDisplayValueAction = (payload) => ({
  type: UPDATE_DISPLAY_VALUE,
  payload,
});
export const updateItemsAction = (payload) => ({ type: UPDATE_ITEMS, payload });
export const updateTotalPagesAction = (payload) => ({
  type: UPDATE_TOTAL_PAGES,
  payload,
});
export const updateCurrentPageAction = (payload) => ({
  type: UPDATE_CURRENT_PAGE,
  payload,
});

export const INITIAL_STATE = {
  searchValue: '',
  displayValue: '',
  items: [],
  totalPages: 0,
  currentPage: 1,
};

const ScrollableSelectFieldReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };

    case UPDATE_DISPLAY_VALUE:
      return {
        ...state,
        displayValue: action.payload,
      };

    case UPDATE_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case UPDATE_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      };

    case UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    default:
      return state;
  }
};

export default ScrollableSelectFieldReducer;
