/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import WarningIcon from '@mui/icons-material/WarningTwoTone';
import TooltipLabel from '../TooltipLabel';
import Button from '../Button';

import './RepeatableField.scss';

const RepeatableField = ({
  label,
  tooltip,
  className,
  required,
  helperText,
  component: RepeatableComponent,
  values,
  max,
  addLabel,
  onChange,
  onAdd,
  onRemove,
  warningMessage,
  ...otherProps
}) => {
  const onComponentChange =
    (index, value) =>
    (event, ...args) => {
      onChange(index, event, value, ...args);
    };

  const onRemoveClick = (index, value) => () => {
    onRemove(index, value);
  };

  const containerClassName = classnames(
    'RepeatableField MRets__RepeatableField',
    className,
  );

  return (
    <FormControl variant="standard" className={containerClassName}>
      {!!label && (
        <InputLabel style={{ pointerEvents: 'auto' }}>
          {!tooltip && label}
          {!!tooltip && <TooltipLabel tooltip={tooltip} label={label} />}
          {required && (
            <span className="MRets__RepeatableField__Required"> *</span>
          )}
          {!!warningMessage && (
            <TooltipLabel
              className="MRets__RepeatableField__WarningMessage"
              tooltip={warningMessage}
              icon={WarningIcon}
            />
          )}
        </InputLabel>
      )}

      <div className="RepeatableField__Content">
        <div className="RepeatableField__Items">
          {values.map((value, index) => (
            <div key={`repeatable-${index}`} className="RepeatableField__Item">
              <div className="RepeatableField__Item__Component">
                <RepeatableComponent
                  index={index}
                  {...otherProps}
                  value={value}
                  onChange={onComponentChange(index, value)}
                  onRemove={onRemoveClick(index, value)}
                />
              </div>
              <IconButton
                onClick={onRemoveClick(index, value)}
                className="RepeatableField__Item__RemoveButton"
                size="large"
              >
                <RemoveIcon />
              </IconButton>
            </div>
          ))}
        </div>

        {values.length < max && (
          <Button className="RepeatableField__AddButton" onClick={onAdd}>
            <AddIcon className="RepeatableField__AddButton__Icon" />
            <span className="RepeatableField__AddButton__Label">
              {addLabel}
            </span>
          </Button>
        )}
      </div>

      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

RepeatableField.defaultProps = {
  label: null,
  helperText: null,
  tooltip: null,
  required: false,
  addLabel: 'Add',
  className: '',
  values: [],
  max: 999,
  warningMessage: null,
};

RepeatableField.propTypes = {
  component: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  max: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
  tooltip: PropTypes.string,
  values: PropTypes.array,
  required: PropTypes.bool,
  addLabel: PropTypes.string,
  warningMessage: PropTypes.string,
};

export default RepeatableField;
