import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../Button';

import './Modal.scss';

const Modal = ({
  open,
  onClose,
  title,
  actions,
  className,
  children,
  hideCloseButton,
  ...otherProps
}) => {
  const descriptionElementRef = React.useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const rootClassName = classnames('MRetsModal', className);
  const paperClassName = classnames('MRetsModal__Paper', {
    [`${className}__Paper`]: !!className,
  });
  const titleClassName = classnames('MRetsModal__Title', {
    [`${className}__Title`]: !!className,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className={rootClassName}
      disableEnforceFocus
      PaperProps={{
        className: paperClassName,
      }}
      {...otherProps}
    >
      {title && (
        <DialogTitle id="scroll-dialog-title" className={titleClassName}>
          {title}
        </DialogTitle>
      )}
      {!hideCloseButton && (
        <button
          className="MRetsModal__CloseButton"
          type="button"
          onClick={onClose}
        >
          <CloseIcon />
        </button>
      )}

      <DialogContent
        id="scroll-dialog-description"
        ref={descriptionElementRef}
        tabIndex={-1}
      >
        {children}
      </DialogContent>
      {actions && actions.length > 0 && (
        <DialogActions>
          <div className="MRetsModal__Actions">
            {actions.map(
              ({ onClick, label, variant, disabled, actionClassName = '' }) => (
                <Button
                  disabled={disabled}
                  key={label}
                  onClick={onClick}
                  variant={variant}
                  className={actionClassName}
                >
                  {label}
                </Button>
              ),
            )}
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.node,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.node.isRequired,
      onClick: PropTypes.func,
      varient: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger']),
    }),
  ),
  hideCloseButton: PropTypes.bool,
};

Modal.defaultProps = {
  className: '',
  actions: undefined,
  open: false,
  title: undefined,
  hideCloseButton: false,
};

export default Modal;
