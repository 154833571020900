import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchCountries, fetchCountryStates } from '../../../utils/helpers';
import CountryState from './CountryState.component';

const CountryStateContainer = (props) => {
  const { selectedCountry, selectedState } = props;
  const [data, setData] = useState(null);

  const fixedSelectedCountry = selectedCountry
    ? data?.countries?.find((c) => c.name === selectedCountry)?.isoCode ||
      selectedCountry
    : null;

  const fixedSelectedState = useMemo(() => {
    let value = selectedState;

    if (data?.states?.length) {
      const selectedStateObj = data.states.find(
        ({ isoCode, name }) =>
          isoCode === selectedState || name === selectedState,
      );

      value = selectedStateObj?.isoCode || value;
    }

    return value;
  }, [selectedState, data?.states]);

  const fetchData = () => {
    let countries = fetchCountries();
    const unitedStates = countries.find((c) => c.isoCode === 'US');
    const canada = countries.find((c) => c.isoCode === 'CA');
    const mexico = countries.find((c) => c.isoCode === 'MX');

    countries = countries.filter(
      (c) => !['US', 'CA', 'MX'].includes(c.isoCode),
    );
    countries = [unitedStates, canada, mexico, ...countries];
    let states = [];

    if (fixedSelectedCountry) {
      const selectedCountryCode = countries.find(
        (c) =>
          c.name === fixedSelectedCountry || c.isoCode === fixedSelectedCountry,
      )?.isoCode;
      states = fetchCountryStates(selectedCountryCode, countries);
    }

    setData({
      countries,
      states,
    });
  };

  const updateCountryStates = (countryCode) => {
    const states = fetchCountryStates(countryCode, data.countries);

    setData({
      ...data,
      states,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data && data.countries) {
      updateCountryStates(fixedSelectedCountry);
    }
  }, [selectedCountry]);

  if (!data) {
    return null;
  }

  return (
    <CountryState
      {...data}
      {...props}
      selectedCountry={fixedSelectedCountry}
      selectedState={fixedSelectedState}
      updateCountryStates={updateCountryStates}
      returnLabels
    />
  );
};

CountryStateContainer.defaultProps = {
  selectedCountry: null,
  selectedState: null,
};

CountryStateContainer.propTypes = {
  selectedCountry: PropTypes.string,
  selectedState: PropTypes.string,
};

export default CountryStateContainer;
