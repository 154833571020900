import React, { useEffect, useReducer, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Checkbox,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import SearchListReducer, {
  INITIAL_STATE,
  updateItemsAction,
  updateSearchValueAction,
  updateTotalPagesAction,
  updateCurrentPageAction,
} from './SearchList.reducer';
import Spinner from '../../Spinner';
import { useDebounce } from '../../../hooks/debounce';
import { useLoading } from '../../../hooks/loading';

import './SearchList.scss';

const SearchList = ({
  onFetchData,
  onSelectedItemsChange,
  selectedItems,
  queryData,
  radio,
  searchKeyName,
  placeholder,
  hideSearch,
}) => {
  const [state, dispatch] = useReducer(SearchListReducer, INITIAL_STATE);

  const { searchValue, items, currentPage, totalPages } = state;
  const { debounce } = useDebounce();
  const { doLoad } = useLoading();
  const { isLoading, setIsLoading } = doLoad();
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const selectedItemsArray = useMemo(() => {
    let value = [];
    if (selectedItems) {
      value = Array.isArray(selectedItems) ? selectedItems : [selectedItems];
    }

    return value;
  }, [selectedItems]);

  const fetchListData = async (pageNumber, value = searchValue) => {
    try {
      let filterQueryData = {
        ...queryData,
        page: {
          ...queryData.page,
          number: pageNumber,
        },
      };
      if (value) {
        filterQueryData = {
          ...queryData,
          filter: {
            ...queryData.filter,
            [searchKeyName]: value,
          },
        };
      }

      const response = await onFetchData(filterQueryData);
      const data = response.data || [];

      const newItems = pageNumber === 1 ? data : items.concat(data);

      dispatch(updateItemsAction(newItems));
      dispatch(updateTotalPagesAction(response?.meta?.page_count));
      dispatch(updateCurrentPageAction(pageNumber));
    } catch (err) {
      // show a message?
    }
  };

  const onShowMore = async () => {
    setIsLoadingMore(true);
    await fetchListData(currentPage + 1, searchValue);
    setIsLoadingMore(false);
  };

  const onFirstPageFetch = async (value = '') => {
    setIsLoading(true);
    await fetchListData(1, value);
    setIsLoading(false);
  };

  useEffect(() => {
    onFirstPageFetch();
  }, []);

  const onSearchChange = (event) => {
    event.preventDefault();
    dispatch(updateSearchValueAction(event.target.value));
    debounce(() => {
      onFirstPageFetch(event.target.value);
    }, 800);
  };

  const getItemsIds = (sItems) => {
    return sItems.map((si) => si.id);
  };

  const onCheckboxClick = (item) => () => {
    const newSelectedItems = [...selectedItemsArray];
    const elementIndex = getItemsIds(selectedItemsArray).indexOf(item.id);
    if (elementIndex >= 0) {
      newSelectedItems.splice(elementIndex, 1);
    } else {
      newSelectedItems.push(item);
    }

    onSelectedItemsChange(newSelectedItems);
  };

  const onRadioClick = (item) => () => {
    onSelectedItemsChange(item);
  };

  return (
    <div className="SearchList">
      {!hideSearch && (
        <div className="SearchList__Search">
          <TextField
            variant="standard"
            value={searchValue}
            onChange={onSearchChange}
            fullWidth
            placeholder={placeholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}

      {isLoading && (
        <div className="SearchList__Loading">
          <Spinner />
        </div>
      )}
      {!isLoading && items.length === 0 && (
        <div className="SearchList__NoResults">No Results</div>
      )}
      {!isLoading && items.length > 0 && (
        <InfiniteScroll
          className="SearchList__Options"
          dataLength={items.length}
          next={onShowMore}
          hasMore={currentPage < totalPages}
          height={400}
        >
          {items.map((item) => (
            <div key={item.id} className="SearchList__OptionItem">
              <FormControlLabel
                control={
                  <React.Fragment>
                    {radio && (
                      <Radio
                        checked={selectedItemsArray[0]?.id === item.id}
                        onChange={onRadioClick(item)}
                        color="primary"
                        value={item.id}
                      />
                    )}
                    {!radio && (
                      <Checkbox
                        checked={
                          !!selectedItemsArray.find((si) => si.id === item.id)
                        }
                        onChange={onCheckboxClick(item)}
                        color="primary"
                        value={item.id}
                      />
                    )}
                  </React.Fragment>
                }
                label={item.name}
              />
            </div>
          ))}
        </InfiniteScroll>
      )}
      {isLoadingMore && (
        <div className="SearchList__NextPageLoading">
          <Spinner />
        </div>
      )}
    </div>
  );
};

SearchList.defaultProps = {
  radio: false,
  placeholder: '',
  hideSearch: false,
  selectedItems: null,
  searchKeyName: '',
  queryData: {},
};

SearchList.propTypes = {
  onFetchData: PropTypes.func.isRequired,
  selectedItems: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  onSelectedItemsChange: PropTypes.func.isRequired,
  queryData: PropTypes.object,
  radio: PropTypes.bool,
  searchKeyName: PropTypes.string,
  placeholder: PropTypes.string,
  hideSearch: PropTypes.bool,
};

export default SearchList;
