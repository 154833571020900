/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  InputLabel,
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';

import { stringToDate, parseDateToString } from '../../../utils/helpers';
import FieldPopover from '../FieldPopover';
import MonthPicker from '../MonthPicker';

import './MonthPickerField.scss';

const MonthPickerField = ({ value, dateFormat, onChange, label, required }) => {
  const validateValue = (newValue) => {
    let isNewValueSelected = !!newValue;
    if (isRange) {
      isNewValueSelected = newValue[0] && newValue[1];
    }
    return isNewValueSelected;
  };

  const formatNewValue = (newValue) => {
    let formattedNewValue = '';
    if (isRange) {
      formattedNewValue = [
        format(newValue[0], dateFormat),
        format(newValue[1], dateFormat),
      ];
    } else {
      formattedNewValue = format(newValue, dateFormat);
    }

    return formattedNewValue;
  };

  const buildDateFromValue = (date) => {
    let newDate = date;
    if (date) {
      newDate = stringToDate(date);
    }
    return newDate;
  };

  const buildInitialValue = (valueParam, isRange) => {
    let newValue;
    if (isRange) {
      newValue = [
        buildDateFromValue(valueParam[0]),
        buildDateFromValue(valueParam[1]),
      ];
    } else {
      newValue = buildDateFromValue(valueParam);
    }
    return newValue;
  };

  const isRange = Array.isArray(value);
  const initialValue = buildInitialValue(value, isRange);

  const isRangeSelected = isRange && initialValue[0] && initialValue[1];
  const isSingleSelected = !isRange && initialValue;

  const [formattedValue, setFormattedValue] = useState(
    validateValue(initialValue) ? formatNewValue(initialValue) : null,
  );

  const onToggleType = () => {
    const newValue = isRange ? null : [null, null];

    setFormattedValue(newValue);
    onChange({ target: { value: newValue } });
  };

  const parseValuesToString = (dateOrRange) => {
    let parsedDate = null;

    if (isRange) {
      parsedDate = [
        parseDateToString(dateOrRange[0]),
        parseDateToString(dateOrRange[1]),
      ];
    } else {
      parsedDate = parseDateToString(dateOrRange);
    }

    return parsedDate;
  };

  const onDateChange = ({ target: { value: dateOrRange } }) => {
    const newValue = parseValuesToString(dateOrRange);
    onChange({ target: { value: newValue } });

    if (validateValue(dateOrRange)) {
      setFormattedValue(formatNewValue(dateOrRange));
    }
  };

  const onDateClear = () => {
    const newValue = isRange ? [null, null] : null;
    onChange({ target: { value: newValue } });
    setFormattedValue(newValue);
  };

  const buildLabel = () => {
    let returnValue = 'MM/YYYY';
    if (isRange && !!formattedValue) {
      returnValue = [
        formattedValue[0] || returnValue,
        formattedValue[1] || returnValue,
      ];
    } else {
      returnValue = formattedValue || returnValue;
    }
    return returnValue;
  };

  const isValueSelected = () => {
    let valueSelected = false;
    if (isRange && !!formattedValue) {
      valueSelected = !!formattedValue[0] || !!formattedValue[1];
    } else {
      valueSelected = !!formattedValue;
    }
    return valueSelected;
  };

  const fieldLabel = buildLabel();
  return (
    <FormControl variant="standard" className="MonthPickerField">
      {!!label && (
        <InputLabel shrink style={{ pointerEvents: 'auto' }}>
          <span>{label}</span>
          {required && (
            <span className="MRets__MonthPickerField__Required"> *</span>
          )}
        </InputLabel>
      )}
      <FieldPopover
        className="MonthPickerField__Popover"
        icon={<EventIcon className="MonthPickerField__Popover__Icon" />}
        label={isRange ? `${fieldLabel[0]} - ${fieldLabel[1]}` : fieldLabel}
        valueSelected={isValueSelected()}
        componentProps={{
          variant: 'outlined',
        }}
      >
        <div className="MonthPickerField__Body">
          <RadioGroup
            className="MonthPickerField__Type"
            name="MonthPickerFieldType"
            value={isRange ? 'range' : 'single'}
            onChange={onToggleType}
          >
            <FormControlLabel
              value="single"
              control={
                <Radio
                  className="MonthPickerField__TypeOption MonthPickerField__TypeOption--single"
                  color="primary"
                />
              }
              label="Single"
            />
            <FormControlLabel
              value="range"
              control={
                <Radio
                  className="MonthPickerField__TypeOption MonthPickerField__TypeOption--range"
                  color="primary"
                />
              }
              label="Range"
            />
          </RadioGroup>
          {isRange && (
            <div className="MonthPickerField__RangeValue">
              {initialValue.map((dateValue, i) => (
                <div className="MonthPickerField__Value" key={i}>
                  <Typography variant="caption" component="div">
                    {i === 0 ? 'Start' : 'End'}
                  </Typography>

                  <div>
                    {!!dateValue && format(dateValue, dateFormat)}
                    {!dateValue && '--/----'}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="MonthPickerField__Calendar">
            <MonthPicker
              value={initialValue}
              onChange={onDateChange}
              dateFormat={dateFormat}
              isRange={isRange}
              isFulfilled={Boolean(isRangeSelected || isSingleSelected)}
            />
          </div>
          <div className="MonthPickerField__ClearButton">
            <Button variant="outlined" onClick={onDateClear}>
              Clear Date Filter
            </Button>
          </div>
        </div>
      </FieldPopover>
    </FormControl>
  );
};

MonthPickerField.defaultProps = {
  dateFormat: 'MM-dd-yyyy',
  value: null,
  label: null,
  required: false,
};

MonthPickerField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  dateFormat: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default MonthPickerField;
