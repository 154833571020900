import React, { useReducer, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import {
  Checkbox,
  FormControlLabel,
  Radio,
  InputLabel,
  FormControl,
  Popover,
  ClickAwayListener,
  InputAdornment,
  IconButton,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningTwoTone';
import ScrollableSelectFieldReducer, {
  INITIAL_STATE,
  updateItemsAction,
  updateSearchValueAction,
  updateDisplayValueAction,
  updateTotalPagesAction,
  updateCurrentPageAction,
} from './ScrollableSelectField.reducer';
import TextField from '../TextField';
import SelectedOptions from '../MultipleSelectField/SelectedOptions.component';
import Spinner from '../../Spinner';
import { useDebounce } from '../../../hooks/debounce';
import { useLoading } from '../../../hooks/loading';
import { useNotifications } from '../../../hooks/notifications';

import './ScrollableSelectField.scss';
import TooltipLabel from '../TooltipLabel';

const ScrollableSelectField = ({
  onFetchData,
  onSelectedItemsChange,
  selectedItems,
  queryData,
  searchKeyName,
  placeholder,
  label,
  required,
  showOptions,
  showOptionsAtRight,
  multiple,
  labelKey,
  fallbackLabelKey,
  valueKey,
  popoverOnTop,
  warningMessage,
}) => {
  const [state, dispatch] = useReducer(
    ScrollableSelectFieldReducer,
    INITIAL_STATE,
  );

  const { searchValue, displayValue, items, currentPage, totalPages } = state;
  const { debounce } = useDebounce();
  const { doLoad } = useLoading();
  const { onError } = useNotifications();
  const { isLoading, setIsLoading } = doLoad();
  const [anchorEl, setAnchorEl] = useState(null);
  const searchInputRef = React.useRef();

  const selectedItemsArray = useMemo(() => {
    let value = [];
    if (selectedItems) {
      value = Array.isArray(selectedItems) ? selectedItems : [selectedItems];
    }

    if (!multiple && !!selectedItems && searchValue.length === 0) {
      dispatch(updateDisplayValueAction(selectedItems[labelKey]));
    } else {
      dispatch(updateDisplayValueAction(searchValue));
    }

    return value;
  }, [selectedItems]);

  const fetchListData = async (pageNumber, value = searchValue) => {
    try {
      let filterQueryData = {
        ...queryData,
        page: {
          ...queryData.page,
          number: pageNumber,
        },
      };
      if (value) {
        filterQueryData = {
          ...queryData,
          filter: {
            [searchKeyName]: value,
          },
        };
      }

      const response = await onFetchData(filterQueryData);
      const data = response.data || [];

      const newItems = pageNumber === 1 ? data : items.concat(data);

      dispatch(updateItemsAction(newItems));
      dispatch(updateTotalPagesAction(response?.meta?.page_count));
      dispatch(updateCurrentPageAction(pageNumber));
    } catch (err) {
      onError('An error occurred fetching data for a dropdown');
    }
  };

  const onShowMore = async () => {
    await fetchListData(currentPage + 1, searchValue);
  };

  const onFirstPageFetch = async (value = '') => {
    setIsLoading(true);
    await fetchListData(1, value);
    setIsLoading(false);
  };

  const onSearchChange = (event) => {
    event.preventDefault();
    dispatch(updateSearchValueAction(event.target.value));
    dispatch(updateDisplayValueAction(event.target.value));
    debounce(async () => {
      onFirstPageFetch(event.target.value);
    }, 800);
  };

  const getItemsIds = (sItems) => {
    return sItems.map((si) => si.id);
  };

  const onCheckboxClick = (item) => () => {
    const newSelectedItems = [...selectedItemsArray];
    const elementIndex = getItemsIds(selectedItemsArray).indexOf(item.id);
    if (elementIndex >= 0) {
      newSelectedItems.splice(elementIndex, 1);
    } else {
      newSelectedItems.push(item);
    }

    onSelectedItemsChange({ target: { value: newSelectedItems } });
  };

  const onRadioClick = (item) => () => {
    dispatch(updateSearchValueAction(INITIAL_STATE.searchValue));
    onSelectedItemsChange({ target: { value: [item] } });
    onClosePopover();
  };

  const buildPlaceholder = () => {
    let newPlaceholder = placeholder;
    if (!multiple && selectedItemsArray[0]) {
      newPlaceholder =
        selectedItemsArray[0][labelKey] ||
        selectedItemsArray[0][fallbackLabelKey];
    }
    if (multiple && selectedItemsArray.length > 0) {
      newPlaceholder = `${selectedItemsArray.length} selected`;
    }
    return newPlaceholder;
  };

  const onOpenPopover = (event) => {
    event.stopPropagation();
    const newAnchorEl = searchInputRef.current;
    setAnchorEl(newAnchorEl);
  };

  const onSearchTextClick = (event) => {
    onOpenPopover(event);
    onFirstPageFetch(searchValue);
  };

  const onClosePopover = () => {
    setAnchorEl(null);
  };

  const inputProps = {
    inputProps: {
      style: {},
    },
  };

  return (
    <div
      className={classnames('ScrollableSelectField', {
        'ScrollableSelectField--row': showOptionsAtRight,
        'ScrollableSelectField--column': !showOptionsAtRight,
        'ScrollableSelectField--warningMessage': warningMessage,
      })}
    >
      <FormControl className="ScrollableSelectField__Select" variant="outlined">
        {!!label && (
          <InputLabel shrink style={{ pointerEvents: 'auto' }}>
            <span>{label}</span>
            {required && (
              <span className="MRets__ScrollableSelectField__Required"> *</span>
            )}
            {!!warningMessage && (
              <TooltipLabel
                className="MRets__ScrollableSelectField__WarningMessage"
                tooltip={warningMessage}
                icon={WarningIcon}
              />
            )}
          </InputLabel>
        )}

        <div className="ScrollableSelectField__Body">
          <div className="ScrollableSelectField__Search" ref={searchInputRef}>
            <TextField
              value={displayValue}
              onChange={onSearchChange}
              fullWidth
              placeholder={buildPlaceholder()}
              onClick={onSearchTextClick}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={onSearchTextClick}
                      edge="end"
                      size="large"
                    >
                      {anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...inputProps}
            />
          </div>
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={onClosePopover}
            anchorOrigin={{
              vertical: popoverOnTop ? 'top' : 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: popoverOnTop ? 'bottom' : 'top',
              horizontal: 'left',
            }}
            className="ScrollableSelectField__Popover"
            slotProps={{
              paper: {
                className: 'ScrollableSelectField__Popover__Paper',
              },
            }}
            disableAutoFocus
            disableEnforceFocus
          >
            <ClickAwayListener onClickAway={onClosePopover}>
              <div className="ScrollableSelectField__Popover__Content">
                {isLoading && (
                  <div className="ScrollableSelectField__Loading">
                    <Spinner />
                  </div>
                )}
                {!isLoading && (
                  <React.Fragment>
                    {items.length > 0 && (
                      <InfiniteScroll
                        className="ScrollableSelectField__Options"
                        dataLength={items.length}
                        next={onShowMore}
                        hasMore={currentPage < totalPages}
                        loader={
                          <div className="ScrollableSelectField__NextPageLoading">
                            <Spinner />
                          </div>
                        }
                        height={300}
                      >
                        {items.map((item) => (
                          <div
                            key={item[valueKey]}
                            className={classnames(
                              'ScrollableSelectField__OptionItem',
                              {
                                'ScrollableSelectField__OptionItem--selected':
                                  selectedItemsArray.find(
                                    (i) => item[valueKey] === i[valueKey],
                                  ),
                              },
                            )}
                          >
                            <FormControlLabel
                              key={
                                selectedItemsArray?.[0]?.[valueKey] ||
                                Math.random().toString().slice(0, 5)
                              }
                              control={
                                <React.Fragment>
                                  {!multiple && (
                                    <Radio
                                      checked={
                                        selectedItemsArray[0] &&
                                        selectedItemsArray[0][valueKey] ===
                                          item[valueKey]
                                      }
                                      onChange={onRadioClick(item)}
                                      color="primary"
                                      value={item[valueKey]}
                                    />
                                  )}
                                  {multiple && (
                                    <Checkbox
                                      checked={
                                        !!selectedItemsArray.find(
                                          (i) => item[valueKey] === i[valueKey],
                                        )
                                      }
                                      onChange={onCheckboxClick(item)}
                                      color="primary"
                                      value={item[valueKey]}
                                    />
                                  )}
                                </React.Fragment>
                              }
                              label={item[labelKey] || item[fallbackLabelKey]}
                            />
                          </div>
                        ))}
                      </InfiniteScroll>
                    )}
                    {items.length === 0 && (
                      <div className="ScrollableSelectField__EmptyList">
                        No Results
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </ClickAwayListener>
          </Popover>
        </div>
      </FormControl>
      {showOptions && multiple && (
        <div className="MultipleSelectField__SelectedOptions">
          <SelectedOptions
            selectedOptions={selectedItemsArray}
            valueKey={valueKey}
            labelKey={labelKey}
            fallbackLabelKey={fallbackLabelKey}
            onSelectedOptionDelete={(item) => onCheckboxClick(item)()}
          />
        </div>
      )}
    </div>
  );
};

ScrollableSelectField.defaultProps = {
  placeholder: 'Select...',
  label: null,
  required: false,
  showOptionsAtRight: true,
  showOptions: true,
  multiple: true,
  selectedItems: null,
  searchKeyName: 'name',
  queryData: {},
  labelKey: 'name',
  fallbackLabelKey: '',
  valueKey: 'id',
  popoverOnTop: true,
  warningMessage: null,
};

ScrollableSelectField.propTypes = {
  onFetchData: PropTypes.func.isRequired,
  selectedItems: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  onSelectedItemsChange: PropTypes.func.isRequired,
  queryData: PropTypes.object,
  searchKeyName: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  fallbackLabelKey: PropTypes.string,
  required: PropTypes.bool,
  showOptionsAtRight: PropTypes.bool,
  showOptions: PropTypes.bool,
  multiple: PropTypes.bool,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  popoverOnTop: PropTypes.bool,
  warningMessage: PropTypes.string,
};

export default ScrollableSelectField;
