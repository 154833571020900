export const COUNTRY_STATE_CONFIG = {
  country: {
    label: 'Country',
    pickOneOption: 'Select Country',
    config: {
      valueKey: 'isoCode',
      fallbackKey: 'name',
      labelKey: 'name',
    },
  },
  state: {
    label: 'State',
    pickOneOption: 'Select State',
    config: {
      valueKey: 'isoCode',
      fallbackKey: 'name',
      labelKey: 'name',
    },
  },
};
