/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  FormControl,
  FormHelperText,
  // IconButton,
  InputLabel,
} from '@mui/material';
// import ClearIcon from '@mui/icons-material/Close';
import ReactDatePicker from 'react-datepicker';
import WarningIcon from '@mui/icons-material/WarningTwoTone';
// import TextField from '../TextField';
import TooltipLabel from '../TooltipLabel';
import {
  addDaysToDate,
  parseDateToString,
  stringToDate,
  formattedUtcDateString,
} from '../../../utils/helpers';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

const DatePicker = ({
  label,
  helperText,
  value,
  dateFormat,
  onChange,
  onClear,
  clearable,
  error,
  required,
  tooltip,
  minDate,
  maxDate,
  warningMessage,
  isUTC,
}) => {
  const onClearClick = () => {
    if (onClear) {
      onClear();
    } else {
      onChange({ target: { value: '' } });
    }
  };

  const onDateChange = (dateOrRange) => {
    if (dateOrRange === null) {
      onClearClick();
    } else {
      try {
        let dateValue = parseDateToString(dateOrRange);

        if (minDate) {
          if (dateOrRange <= minDate) {
            dateValue = null;
          }
        }

        if (maxDate) {
          if (dateOrRange > addDaysToDate(new Date(maxDate), 1)) {
            dateValue = null;
          }
        }
        onChange({ target: { value: dateValue } });
      } catch (err) {
        // Nothing to do here
      }
    }
  };

  const displayClearButton = value && (clearable || onClear);

  const className = classnames('DatePicker MRets__DatePicker', {
    'DatePicker--warningMessage': warningMessage,
  });

  const selectedDate = useMemo(() => {
    let newValue = null;

    if (value) {
      newValue = isUTC ? formattedUtcDateString(value) : value;
      newValue = stringToDate(newValue);
    }

    return newValue;
  }, [value]);

  return (
    <FormControl variant="standard" className={className} error={error}>
      {!!label && (
        <InputLabel shrink style={{ pointerEvents: 'auto' }}>
          {!tooltip && label}
          {!!tooltip && <TooltipLabel tooltip={tooltip} label={label} />}
          {required && <span className="MRets__DatePicker__Required"> *</span>}
          {!!warningMessage && (
            <TooltipLabel
              className="MRets__DatePicker__WarningMessage"
              tooltip={warningMessage}
              icon={WarningIcon}
            />
          )}
        </InputLabel>
      )}

      <ReactDatePicker
        className="MRets__DatePicker"
        popperClassName="MRets__DatePicker__Popper"
        calendarClassName="MRets__DatePicker__Calendar"
        selected={selectedDate}
        onChange={onDateChange}
        dateFormat={dateFormat}
        isClearable={displayClearButton}
        minDate={minDate ? new Date(minDate) : null}
        maxDate={maxDate ? new Date(maxDate) : null}
        showIcon
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />

      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

DatePicker.defaultProps = {
  label: null,
  helperText: null,
  value: null,
  error: false,
  required: false,
  clearable: true,
  tooltip: null,
  onClear: null,
  dateFormat: 'MM/dd/yyyy',
  minDate: null,
  maxDate: null,
  warningMessage: null,
  isUTC: false,
};

DatePicker.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateFormat: PropTypes.string,
  onClear: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  warningMessage: PropTypes.string,
  isUTC: PropTypes.bool,
};

export default DatePicker;
