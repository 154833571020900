import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BackLink = ({ link, linkLabel }) => {
  if (!link) {
    return null;
  }

  return (
    <div className="BackLink">
      <Link className="BackLink__LinkLabel" to={link}>
        {linkLabel}
      </Link>
    </div>
  );
};

BackLink.defaultProps = {
  link: '',
  linkLabel: 'View All Reports',
};

BackLink.propTypes = {
  link: PropTypes.string,
  linkLabel: PropTypes.string,
};

export default BackLink;
