export const GENERATION_INCLUDES = {
  rec: {
    includeAll: [
      'user',
      'organization',
      'reporting_entity',
      'generator.generator_fuels.fuel_source.fuel_type',
      'generator.generator_aggregate',
      'generator_aggregate.generators',
      'certificates.generator',
      'certificates.generator_fuel',
      'generator_fuel_remainder_adjustments',
    ],
    includeUniqueBy: {
      'user.id': ['user'],
      'generators.name': ['generator'],
      'generators.mrets_id': ['generator'],
      'organizations.name': ['organization'],
      'reporting_entities.name': ['reporting_entity'],
      'generator_aggregate.reporting_unit_id': ['generator_aggregate'],
      'fuel_sources.description': ['generator.generator_fuels.fuel_source'],
      'fuel_types.name': ['generator.generator_fuels.fuel_source.fuel_type'],
    },
  },
  rtc: {
    includeAll: [
      'user',
      'organization',
      'reporting_entity',
      'generator.generator_fuels.feedstock.thermal_resource',
    ],
    includeUniqueBy: {
      'user.id': ['user'],
      'generators.name': ['generator'],
      'generators.mrets_id': ['generator'],
      'organizations.name': ['generator.organization'],
      'fuel_sources.description': ['generator.generator_fuels.feedstock'],
      'fuel_types.name': [
        'generator.generator_fuels.feedstock.thermal_resource',
      ],
    },
  },
};

export const GENERATION_FIELDS = {
  rec: {
    generators: [
      'name',
      'mrets_id',
      'facility_name',
      'generator_fuels',
      'organization',
      'generator_aggregate',
    ],
    user: ['first_name', 'last_name', 'email'],
    organizations: ['name'],
    generator_fuels: ['fuel_source', 'label'],
    fuel_sources: ['fuel_type', 'description'],
  },
  rtc: {
    generators: [
      'name',
      'mrets_id',
      'facility_name',
      'generator_fuels',
      'organization',
      'generator_pipeline_connected',
    ],
    user: ['first_name', 'last_name', 'email'],
    organizations: ['name'],
    generator_fuels: ['feedstock'],
    feedstocks: ['thermal_resource', 'description'],
  },
};

export const ANNUAL_PRODUCTION_INCLUDES = {
  rec: {
    includeAll: [
      'organization',
      'reporting_entity',
      'generator_fuels.fuel_source.fuel_type',
      'notes.user',
    ],
    includeUniqueBy: {
      'notes.user': ['notes'],
      'organizations.name': ['organization'],
      'reporting_entities.name': ['reporting_entity'],
      'fuel_sources.description': ['generator_fuels.fuel_source.fuel_type'],
      'fuel_types.name': ['generator_fuels.fuel_source.fuel_type'],
    },
  },
  rtc: {
    includeAll: [
      'organization',
      'generator_fuels.feedstock.thermal_resource',
      'reporting_entity',
    ],
    includeUniqueBy: {
      'organizations.name': ['organization'],
      'reporting_entities.name': ['reporting_entity'],
      'fuel_sources.description': [
        'generator_fuels.feedstock.thermal_resource',
      ],
      'fuel_types.name': ['generator_fuels.feedstock.thermal_resource'],
    },
  },
};

export const ANNUAL_PRODUCTION_FIELDS = {
  rec: {
    generators: [
      'name',
      'facility_name',
      'mrets_id',
      'generator_fuels',
      'organization',
      'state_province',
      'nameplate_capacity',
      'annual_production',
      'reporting_entity',
    ],
    organizations: ['name'],
    generator_fuels: ['fuel_source'],
    fuel_sources: ['description', 'fuel_type'],
    notes: ['content', 'visibility', 'created_at', 'user'],
  },
  rtc: {
    generators: [
      'name',
      'facility_name',
      'mrets_id',
      'generator_fuels',
      'organization',
      'state_province',
      'annual_production',
      'generator_pipeline_connected',
    ],
    organizations: ['name'],
    generator_fuels: ['feedstock'],
    feedstocks: ['description', 'thermal_resource'],
    notes: ['content', 'visibility', 'created_at', 'user'],
  },
};

export const VALIDATION_CURVES_INCLUDES = {
  rec: {
    includeAll: ['generator'],
    includeUniqueBy: {
      'generators.name': ['generator'],
      'generators.facility_name': ['generator'],
      'generators.mrets_id': ['generator'],
    },
  },
  rtc: {
    includeAll: ['generator'],
    includeUniqueBy: {
      'generators.name': ['generator'],
      'generators.facility_name': ['generator'],
      'generators.mrets_id': ['generator'],
    },
  },
};

export const VALIDATION_CURVES_FIELDS = {
  rec: {
    generators: ['name', 'facility_name', 'mrets_id'],
  },
  rtc: {
    generators: ['name', 'facility_name', 'mrets_id'],
  },
};
