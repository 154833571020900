import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLoading } from '../../../../hooks/loading';
import { useUser } from '../../../../hooks/user';
import api from '../../../../services/api';
import OrganizationsMenu from './OrganizationsMenu.component';

const OrganizationsMenuContainer = () => {
  const { user, switchToOrg } = useUser();
  const { appDoLoad } = useLoading();
  const history = useHistory();

  const { userOrganization } = user || {};

  if (!userOrganization) {
    return null;
  }

  const onOrganizationChange = async (org) => {
    appDoLoad(true);
    history.replace('/dashboard');
    await switchToOrg(org);
    appDoLoad(false);
  };

  const fetchUserOrganizations = async (page = 1, searchValue = '') => {
    const userOrganizationsResponse = await api.user.getUserOrganizations(
      page,
      searchValue,
    );

    return userOrganizationsResponse;
  };

  return (
    <OrganizationsMenu
      isAdmin={user.isAdmin()}
      hasMultipleOrgs={user.hasMultipleOrgs}
      onChange={onOrganizationChange}
      fetchUserOrganizations={fetchUserOrganizations}
    />
  );
};

export default OrganizationsMenuContainer;
