import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ title, subTitle, pretitle }) => {
  return (
    <div className="Title__Container">
      {subTitle && <h3 className="SubTitle">{subTitle}</h3>}
      <h2>
        <span className="PreTitle">{pretitle}</span>
        <span className="Title">{title}</span>
      </h2>
    </div>
  );
};

Title.defaultProps = {
  subTitle: '',
  pretitle: '',
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  pretitle: PropTypes.string,
};

export default Title;
