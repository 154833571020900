/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, ClickAwayListener, Popper } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames';
import { useLoading } from '../../../../hooks/loading';
import Spinner from '../../../Spinner';
import Options from './OrganizationMenuOptions.component';
import { useDebounce } from '../../../../hooks/debounce';

import './OrganizationsMenu.scss';
import { useUser } from '../../../../hooks/user';

const OrganizationsMenu = ({
  isAdmin,
  hasMultipleOrgs,
  onChange,
  fetchUserOrganizations,
}) => {
  const { user } = useUser();
  const currentOrganization = user.userOrganization.organization;

  // Menu Hover
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState({
    organizations: [],
    currentPage: 0,
    totalPages: 1,
    hasMultipleResourceTypes: false,
  });
  const { debounce } = useDebounce();
  const { doLoad } = useLoading();
  const { isLoading, setIsLoading } = doLoad(false);

  const open = Boolean(anchorEl);
  const popperId = open ? 'org-menu-popper' : undefined;

  const updateData = (newData) => {
    setData((prevState) => ({
      ...prevState,
      ...newData,
    }));
  };

  const handleClick = async (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);

    if (!data.organizations.length) {
      await onShowMore();
    }
  };

  const closePopper = () => {
    setAnchorEl(null);
  };

  const onOrganizationClick = (org) => {
    onChange(org);
    closePopper();
  };

  const onSearchChange = ({ target: { value } }) => {
    updateData({
      searchValue: value || '',
      organizations: [],
      currentPage: 0,
      totalPages: 1,
    });

    debounce(async () => {
      await onShowMore(value || '');
    }, 1000);
  };

  const onShowMore = async (searchValue = null) => {
    if (!isLoading) {
      setIsLoading(true);
      const hasSearchValue = searchValue !== null;
      const pageNumber = hasSearchValue ? 1 : data.currentPage + 1;
      const response = await fetchUserOrganizations(
        pageNumber,
        hasSearchValue ? searchValue : data.searchValue,
      );

      const orgs = response.data || [];
      const newOrganizations =
        pageNumber === 1 ? orgs : data.organizations.concat(orgs);

      // eslint-disable-next-line prefer-destructuring
      let hasMultipleResourceTypes = data.hasMultipleResourceTypes;
      if (!hasMultipleResourceTypes) {
        hasMultipleResourceTypes = !!orgs.find(
          ({ resource_type: resourceType }) =>
            resourceType !== currentOrganization.resource_type,
        );
      }

      updateData({
        organizations: newOrganizations,
        currentPage: pageNumber,
        totalPages: response?.meta?.page_count,
        searchValue: hasSearchValue ? searchValue : data.searchValue,
        hasMultipleResourceTypes,
      });

      setIsLoading(false);
    }
  };

  const organizationMenuRootClassnames = classNames('OrganizationsMenuRoot', {
    'OrganizationsMenuRoot--multiple': hasMultipleOrgs,
  });

  const OrganizationMenuRootcomponent = hasMultipleOrgs ? 'button' : 'div';

  return (
    <div className="OrganizationsMenu OrganizationsMenu__Container">
      <OrganizationMenuRootcomponent
        className={organizationMenuRootClassnames}
        aria-describedby={popperId}
        onClick={hasMultipleOrgs ? handleClick : undefined}
      >
        {currentOrganization.name}
        {hasMultipleOrgs && (
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path d="M0 0h24v24H0" />
              <path
                className="stroke-transition stroke-white-hover-dark"
                strokeWidth="2"
                d="M7 10l5 5 5-5"
              />
            </g>
          </svg>
        )}
      </OrganizationMenuRootcomponent>
      <Popper
        className="OrganizationMenuPopper"
        popperOptions={{
          modifiers: [
            {
              offset: {
                offset: '8,-8',
              },
            },
          ],
        }}
        placement="top-start"
        id={popperId}
        open={open}
        anchorEl={anchorEl}
      >
        <ClickAwayListener onClickAway={closePopper}>
          <Card className="OrganizationsMenu__Options OrganizationMenuCard">
            <label className="OrganizationMenuSearchLabel" htmlFor="org-search">
              <span className="clip">Search for your organization name</span>
              <input
                className="OrganizationMenuSearchInput"
                autoFocus
                placeholder="Search..."
                value={data.searchValue || ''}
                onChange={onSearchChange}
                type="search"
                autoComplete="off"
                id="org-search"
              />
            </label>

            <InfiniteScroll
              className="OrganizationsMenu__Options"
              dataLength={data.organizations.length}
              next={onShowMore}
              hasMore={data.currentPage < data.totalPages}
              loader={
                <div className="OrganizationsMenu__NextPageLoading">
                  <Spinner />
                </div>
              }
              endMessage={
                <div className="OrganizationsMenu__EndMessage">
                  {!data.organizations.length ? 'No results' : ''}
                </div>
              }
              height={256}
            >
              <Options
                onEscape={closePopper}
                options={data.organizations}
                displayChip={isAdmin || !!data.hasMultipleResourceTypes}
                value={currentOrganization.id}
                onChange={onOrganizationClick}
              />
            </InfiniteScroll>
          </Card>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

OrganizationsMenu.defaultProps = {
  isAdmin: false,
  hasMultipleOrgs: false,
};

OrganizationsMenu.propTypes = {
  isAdmin: PropTypes.bool,
  hasMultipleOrgs: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  fetchUserOrganizations: PropTypes.func.isRequired,
};

export default OrganizationsMenu;
