/* eslint-disable prettier/prettier */
const commonTheme = {
  'breakpoint-not-small': 'screen and (min-width: 48em)',
  'breakpoint-medium': 'screen and (min-width: 60em)',
  'breakpoint-large': 'screen and (min-width: 80em)',
  'breakpoint-large2': 'screen and (min-width: 90em)',
  'breakpoint-extra-large': 'screen and (min-width: 105em)',
  'breakpoint-extra-large2': 'screen and (min-width: 120em)',
  'link-color': '#059cda',
  black: '#17263a',
  'dark-gray': '#2c405a',
  'dark-med-dark-gray': '#5d7390',
  'med-dark-gray': '#8c9eb6',
  'mid-gray': '#bfc8d4',
  'light-mid-gray': '#e7eaed',
  'light-gray': '#f5f7f8',
  white: '#ffffff',
  warning: '#ea5c34',
  'helper-text': 'rgba(0, 0, 0, 0.6)',
  transparent: 'rgba(0, 0, 0, 0)',
  'border-color': 'rgba(0, 0, 0, 0.12)',
  'blue-lightest': '#b9dafe',
  'blue-lighter': '#77b2f4',
  'blue-light': '#569ae7',
  'blue-dark': '#4283cb',
  'blue-darker': '#326eb1',
  'blue-darkest': '#165294',
  'green-lightest': '#aae7c7',
  'green-lighter': '#71d7a2',
  'green-light': '#53bc86',
  'green-dark': '#3ea770',
  'green-darker': '#2c9f63',
  'green-darkest': '#198c50',
  'orange-lightest': '#ffeabf',
  'orange-lighter': '#ffcc66',
  'orange-light': '#ffaa00',
  'orange-dark': '#fd8905',
  'orange-darker': '#e8770e',
  'orange-darkest': '#db6b04',
  'orange2-lightest': '#ffeabf',
  'orange2-darker': '#db6b04',
  red: '#dc3545',
  yellow: '#ffc107',
  green: '#28a745',
  blue: '#4283cb',
  'subtle-blue': '#b9dafe',
  'subtle-green': '#c1e3ca',
  'subtle-yellow': '#f8e6af',
  'dark-subtle-red': '#a00202',
  'dark-subtle-yellow': '#da9d0f',
  'subtle-red': '#f6ccd0',
  'mrets-blue': '#77b2f4',
  'mrets-green': '#71d7a2',
  'chart-light-green': '#71d7a2',
  'chart-mid-green': '#53bc86',
  'chart-med-dark-green': '#2c9f63',
  'chart-dark-green': '#198c50',
  'chart-light-orange': '#ffeabf',
  'chart-mid-orange': '#ffaa00',
  'chart-med-dark-orange': '#e8770e',
  'chart-dark-orange': '#db6b04',
  'chart-light-blue': '#77b2f4',
  'chart-mid-blue': '#569ae7',
  'chart-med-dark-blue': '#326eb1',
  'chart-dark-blue': '#165294',
  'persistent-notifications-bg': '#f6ccd0',
  'persistent-notifications-cta-bg': '#dc3545',
  'spacing-none': '0',
  'spacing-extra-small-1px': '0.063rem',
  'spacing-extra-small-1_5px': '0.094rem',
  'spacing-extra-small-2px': '0.125rem',
  'spacing-extra-small': '0.25rem',
  'spacing-extra-small-1q': 'calc(0.25rem * 1.25)',
  'spacing-extra-small-2q': 'calc(0.25rem * 1.5)',
  'spacing-extra-small-3q': 'calc(0.25rem * 1.75)',
  'spacing-small': '0.5rem',
  'spacing-small-1q': 'calc(0.5rem * 1.25)',
  'spacing-small-2q': 'calc(0.5rem * 1.5)',
  'spacing-small-3q': 'calc(0.5rem * 1.75)',
  'spacing-medium': '1rem',
  'spacing-medium-1q': 'calc(1rem * 1.25)',
  'spacing-medium-2q': 'calc(1rem * 1.5)',
  'spacing-medium-3q': 'calc(1rem * 1.75)',
  'spacing-large': '2rem',
  'spacing-large-1q': 'calc(2rem * 1.25)',
  'spacing-large-2q': 'calc(2rem * 1.5)',
  'spacing-large-3q': 'calc(2rem * 1.75)',
  'spacing-extra-large': '4rem',
  'spacing-extra-large-1q': 'calc(4rem * 1.25)',
  'spacing-extra-large-2q': 'calc(4rem * 1.5)',
  'spacing-extra-large-3q': 'calc(4rem * 1.75)',
  'spacing-extra-extra-large-8': '8rem',
  'spacing-extra-extra-large-10': '10rem',
  'spacing-extra-extra-large-12': '12rem',
  'spacing-extra-extra-large-16': '16rem',
  'spacing-extra-extra-large-20': '20rem',
  'spacing-extra-extra-large-25': '25rem',
  'spacing-extra-extra-large-30': '30rem',
  'spacing-extra-extra-large-40': '40rem',
  'spacing-extra-extra-large-45': '45rem',
  'spacing-extra-extra-large-65': '65rem',
  'lh-1': '1rem',
  'lh-title': '1.33',
  'lh-copy': '1.5',
  'lh-middle': '2',
  'br-1': '0.125rem',
  'br-2': '0.25rem',
  'br-3': '0.5rem',
  'br-4': '1rem',
  'sans-serif': '-apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif',
  serif: 'georgia, times, serif',
  'system-sans-serif': 'sans-serif',
  'system-serif': 'serif',
  code: 'Consolas, monaco, monospace',
  courier: 'Courier Next, courier, monospace',
  'nunito-sans': 'Nunito Sans, -apple-system, BlinkMacSystemFont, HelveticaNeue, Helvetica, Arial, sans-serif',
  bw1: '0.125rem',
  bw2: '0.25rem',
  bw3: '0.5rem',
  bw4: '1rem',
  bw5: '2rem',
  'shadow-1': '0px 0px 4px 2px rgba(0, 0, 0, 0.5)',
  'shadow-2': '0px 0px 8px 0px rgba(0, 0, 0, 0.5)',
  'shadow-3': '2px 2px 4px 2px rgba(0, 0, 0, 0.5)',
  'shadow-4': '2px 2px 8px 0px rgba(0, 0, 0, 0.5)',
  'shadow-5': '4px 4px 8px 0px rgba(0, 0, 0, 0.5)',
  'shadow-6': 'rgba(5, 16, 33, 0.25) 0px 1px 1px, rgba(5, 16, 33, 0.31) 0px 0px 1px 0px',
  'type-scale-h1': '6rem',
  'type-scale-h2': '5rem',
  'type-scale-h3': '4.5rem',
  'type-scale-f1': '3.5rem',
  'type-scale-f2': '2rem',
  'type-scale-f3': '1.5rem',
  'type-scale-f4': '1.25rem',
  'type-scale-f5': '1rem',
  'type-scale-f6': '0.875rem',
  'type-scale-f7': '0.75rem',
  'type-scale-tooltip': '0.8rem',
  'type-scale-f13': '0.825rem',
  'type-scale-f15': '0.94rem',
  'type-scale-custom-3_75': '3.75rem',
  'type-scale-custom-3_1': '3.1rem',
  'type-scale-custom-2_8': '2.8rem',
  'type-scale-custom-2_5': '2.5rem',
  'type-scale-custom-2_2': '2.2rem',
};

export default commonTheme;
