import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  // process.env.JEST_WORKER_ID === undefined means that is NOT being runned by jest
  if (!enabled && process.env.JEST_WORKER_ID === undefined) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

StrictModeDroppable.defaultProps = {
  children: null,
};

StrictModeDroppable.propTypes = {
  children: PropTypes.any,
};

export default StrictModeDroppable;
