const UPDATE_IS_LOADING = 'UPLOAD_BUTTON/UPDATE_IS_LOADING';
const UPDATE_ERROR = 'UPLOAD_BUTTON/UPDATE_ERROR';
const UPDATE_SUCCESS = 'UPLOAD_BUTTON/UPDATE_SUCCESS';
const SET_INITIAL_STATE = 'UPLOAD_BUTTON/SET_INITIAL_STATE';
const UPDATE_CSV_FILE = 'UPLOAD_BUTTON/UPDATE_CSV_FILE';

export const updateIsLoadingAction = (payload) => ({
  type: UPDATE_IS_LOADING,
  payload,
});

export const updateErrorAction = (payload) => ({
  type: UPDATE_ERROR,
  payload,
});

export const updateSuccessAction = (payload) => ({
  type: UPDATE_SUCCESS,
  payload,
});

export const setInitialStateAction = () => ({
  type: SET_INITIAL_STATE,
});

export const updateCsvFileAction = (payload) => ({
  type: UPDATE_CSV_FILE,
  payload,
});

export const INITIAL_STATE = {
  isLoading: false,
  error: false,
  success: '',
  csvFile: null,
};

const UploadbuttonFormReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    case SET_INITIAL_STATE:
      return INITIAL_STATE;

    case UPDATE_CSV_FILE:
      return {
        ...state,
        csvFile: action.payload,
      };

    default:
      return state;
  }
};

export default UploadbuttonFormReducer;
