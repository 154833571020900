import React from 'react';
import PropTypes from 'prop-types';
import PageTopper from '../PageTopper';
import SubNav from '../SubNav';

import './PageHeader.scss';

const PageHeader = ({ title, navItems, children, ...subNavProps }) => {
  return (
    <PageTopper>
      <div className="PageHeader PageHeader__Container minw_800 flex-auto-1-0 flex flex-column pt1">
        <div className="PageHeader__Title flex items-center">
          <h2 className="bw2 bb b--transparent mr4">{title}</h2>
          <SubNav navItems={navItems} {...subNavProps} />
        </div>
        {!!children && <div className="PageHeader__Children">{children}</div>}
      </div>
    </PageTopper>
  );
};

PageHeader.defaultProps = {
  navItems: [],
  children: null,
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  navItems: PropTypes.array,
  children: PropTypes.node,
};

export default PageHeader;
