import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormControl, IconButton, InputLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { readImageFile } from './ImageField.helpers';

import './ImageField.scss';

const ImageField = ({ label, accept, required, file, error, onChange }) => {
  const className = classnames('MRets__ImageField', {
    'MRets__ImageField--with-image': !!file,
    'MRets__ImageField--empty': !file,
  });

  const [preview, setPreview] = useState(null);

  const onDelete = () => {
    setPreview(null);
    onChange({ target: { value: '' } });
  };

  const onImageChange = async ({ target: { files } }) => {
    const value = files[0];

    const newPreview = await readImageFile(value);
    setPreview(newPreview);

    onChange({ target: { value } });
  };

  return (
    <FormControl
      className={className}
      variant="outlined"
      fullWidth
      error={error}
    >
      {!!label && (
        <InputLabel shrink>
          {label}
          {required && <span className="MRets__ImageField__Required"> *</span>}
        </InputLabel>
      )}
      {!file && <input type="file" accept={accept} onChange={onImageChange} />}
      {!!file && (
        <div className="MRets__ImageField__ImageContainer">
          <IconButton onClick={onDelete} size="large">
            <DeleteIcon />
          </IconButton>
          <img src={preview || file} alt={file?.name || file} />
        </div>
      )}
    </FormControl>
  );
};

ImageField.defaultProps = {
  label: null,
  accept: 'image/png, image/jpeg, image/jpg',
  required: false,
  error: false,
  file: null,
};

ImageField.propTypes = {
  label: PropTypes.string,
  accept: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

export default ImageField;
