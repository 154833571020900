export const MIME_TYPES = {
  csv: 'text/csv',
  pdf: 'application/pdf',
  default: 'application/json',
};

export const FUEL_TYPE = {
  models: {
    rec: {
      fuel_type: 'fuel_type',
      fuel_source: 'fuel_source',
    },
    rtc: {
      fuel_type: 'thermal_resource',
      fuel_source: 'feedstock',
    },
  },
};

export const ACTIVE_CERTIFICATES_INCLUDES = {
  rec: {
    includeAll: [
      'account',
      'account.organization',
      'account.program',
      'certificate',
      'certificate.fuel_type',
      'certificate.generator',
      'certificate.generator.generator_fuels.eligibility_bonds.eligibility',
      'certificate.eligibilities',
      'certificate.generation_entry',
      'certificate.generator_fuel.fuel_source',
      'transaction_detail.user_transaction',
      'etag_quantities',
      'etag_quantities.etag',
    ],
    includeUniqueBy: {
      'accounts.name': ['account'],
      'generators.mrets_id': ['certificate.generator'],
      'generators.name': ['certificate.generator'],
      'generators.facility_name': ['certificate.generator'],
      'programs.name': ['account.program'],
      'generators.state_province': ['certificate.generator'],
      'fuel_types.name': ['certificate.fuel_type'],
      'fuel_sources.description': ['certificate.generator_fuel.fuel_source'],
      'eligibilities.long_name': ['certificate.eligibilities'],
      'user_transactions.retirement_type': [
        'transaction_detail.user_transaction',
      ],
      'user_transactions.retirement_reason': [
        'transaction_detail.user_transaction',
      ],
      'user_transactions.retired_to': ['transaction_detail.user_transaction'],
      'user_transactions.compliance_period': [
        'transaction_detail.user_transaction',
      ],
      'organizations.name': ['account.organization'],
    },
  },
  rtc: {
    includeAll: [
      'account.organization',
      'account.program',
      'account',
      'certificate.carbon_pathways',
      'certificate',
      'certificate.eligibilities',
      'certificate.eligibility_bonds.eligibility',
      'certificate.generator',
      'certificate.generator_fuel',
      'certificate.generator_fuel.feedstock',
      'certificate.generation_entry',
      'certificate.thermal_resource',
      'transaction_detail.user_transaction',
      'transaction_detail.carbon_pathway',
    ],
    includeUniqueBy: {
      'accounts.name': ['account'],
      'generators.mrets_id': ['certificate.generator'],
      'generators.name': ['certificate.generator'],
      'generators.facility_name': ['certificate.generator'],
      'programs.name': ['account.program'],
      'generators.state_province': ['certificate.generator'],
      'fuel_types.name': ['certificate.thermal_resource'],
      'fuel_sources.description': ['certificate.generator_fuel.feedstock'],
      'eligibilities.long_name': ['certificate.eligibilities'],
      'user_transactions.retirement_type': [
        'transaction_detail.user_transaction',
      ],
      'user_transactions.retirement_reason': [
        'transaction_detail.user_transaction',
      ],
      'user_transactions.retired_to': ['transaction_detail.user_transaction'],
      'user_transactions.compliance_period': [
        'transaction_detail.user_transaction',
      ],
      'organizations.name': ['account.organization'],
    },
  },
};

export const ACTIVE_CERTIFICATES_FIELDS = {
  rec: {
    fieldsAll: {
      accounts: ['name', 'id', 'short_id', 'organization', 'program'],
      eligibilities: ['short_name', 'long_name', 'slug'],
      fuel_source: 'description',
      fuel_types: ['name'],
      programs: ['name'],
      generator_fuel: ['fuel_source'],
      generators: [
        'name',
        'facility_name',
        'mrets_id',
        'state_province',
        'generator_fuels',
      ],
      generation_entry: ['reported_source'],
      certificates: [
        'eligibilities',
        'fuel_type',
        'generator',
        'generator_fuel',
        'generation_entry',
        'serial_number_base',
        'vintage_date',
      ],
    },
    fieldsUniqueBy: {
      'accounts.name': {
        accounts: ['name'],
      },
    },
  },
  rtc: {
    certificate_quantities: [
      'quantity',
      'serial_number_start',
      'serial_number_end',
      'created_at',
      'updated_at',
      'status',
      'generator_pipeline_connected',
      'certificate',
      'account',
      'transaction_detail',
    ],
    accounts: ['name', 'id', 'short_id', 'organization', 'program'],
    eligibility_bonds: ['details', 'eligibility'],
    eligibilities: ['short_name', 'long_name', 'slug'],
    carbon_pathways: [
      'id',
      'name',
      'tool_name',
      'carbon_intensity',
      'start_date',
      'end_date',
      'grams_co2_per_dth',
      'document_url',
      'document_name',
      'endpoint',
      'endpoint_description',
      'injection_point',
    ],
    thermal_resources: ['name'],
    programs: ['name'],
    generators: ['name', 'facility_name', 'mrets_id', 'state_province'],
    generator_fuel: ['feedstock'],
    feedstock: ['id', 'description'],
    generation_entry: ['reported_source'],
    certificates: [
      'eligibilities',
      'eligibility_bonds',
      'thermal_resource',
      'generator',
      'generator_fuel',
      'serial_number_base',
      'vintage_date',
      'carbon_pathways',
      'generation_entry',
    ],
  },
};

export const RETIREMENT_CERTIFICATES_INCLUDES = {
  rec: {
    includeAll: [
      'account',
      'account.organization',
      'account.program',
      'certificate',
      'certificate.fuel_type',
      'certificate.generator',
      'certificate.generator.generator_fuels.eligibility_bonds.eligibility',
      'certificate.generation_entry',
      'certificate.eligibilities',
      'transaction_detail.user_transaction',
      'transaction_detail.from_account',
      'etag_quantities',
      'etag_quantities.etag',
    ],
    includeUniqueBy: {
      'accounts.name': ['account'],
      'generators.mrets_id': ['certificate.generator'],
      'generators.name': ['certificate.generator'],
      'generators.facility_name': ['certificate.generator'],
      'programs.name': ['account.program'],
      'generators.state_province': ['certificate.generator'],
      'fuel_types.name': ['certificate.fuel_type'],
      'eligibilities.long_name': ['certificate.eligibilities'],
      'user_transactions.retirement_type': [
        'transaction_detail.user_transaction',
      ],
      'user_transactions.retirement_reason': [
        'transaction_detail.user_transaction',
      ],
      'user_transactions.retired_to': ['transaction_detail.user_transaction'],
      'user_transactions.compliance_period': [
        'transaction_detail.user_transaction',
      ],
      'organizations.name': ['account.organization'],
      'etags.etag_id': ['etag_quantities.etag'],
    },
  },
  rtc: {
    includeAll: [
      'account',
      'account.organization',
      'account.program',
      'certificate',
      'certificate.carbon_pathways',
      'certificate.thermal_resource',
      'certificate.generator',
      'certificate.eligibilities',
      'certificate.generation_entry',
      'certificate.generator_fuel',
      'certificate.generator_fuel.feedstock',
      'transaction_detail.user_transaction',
      'transaction_detail.from_account',
      'transaction_detail.carbon_pathway',
    ],
    includeUniqueBy: {
      'accounts.name': ['account'],
      'generators.mrets_id': ['certificate.generator'],
      'generators.name': ['certificate.generator'],
      'generators.facility_name': ['certificate.generator'],
      'programs.name': ['account.program'],
      'generators.state_province': ['certificate.generator'],
      'fuel_types.name': ['certificate.thermal_resource'],
      'fuel_sources.description': ['certificate.generator_fuel.feedstock'],
      'eligibilities.long_name': ['certificate.eligibilities'],
      'user_transactions.retirement_type': [
        'transaction_detail.user_transaction',
      ],
      'user_transactions.retirement_reason': [
        'transaction_detail.user_transaction',
      ],
      'user_transactions.retired_to': ['transaction_detail.user_transaction'],
      'user_transactions.compliance_period': [
        'transaction_detail.user_transaction',
      ],
      'organizations.name': ['account.organization'],
      'carbon_pathways.name': ['transaction_detail.carbon_pathway'],
      'carbon_pathways.tool_name': ['transaction_detail.carbon_pathway'],
    },
  },
};

export const RETIREMENT_CERTIFICATES_FIELDS = {
  rec: {
    accounts: ['name', 'id', 'short_id', 'organization', 'program'],
    eligibilities: ['short_name', 'long_name', 'slug'],
    fuel_types: ['name'],
    programs: ['name'],
    generators: [
      'name',
      'facility_name',
      'mrets_id',
      'state_province',
      'generator_fuels',
    ],
    certificates: [
      'eligibilities',
      'fuel_type',
      'generator',
      'generation_entry',
      'serial_number_base',
      'vintage_date',
    ],
    etag_quantities: ['etag'],
    generation_entry: ['reported_source'],
  },
  rtc: {
    accounts: ['name', 'id', 'short_id', 'organization', 'program'],
    eligibilities: ['short_name', 'long_name', 'slug'],
    carbon_pathways: [
      'id',
      'name',
      'tool_name',
      'carbon_intensity',
      'start_date',
      'end_date',
      'grams_co2_per_dth',
      'document_url',
      'document_name',
      'endpoint',
      'endpoint_description',
      'injection_point',
    ],
    thermal_resources: ['name'],
    programs: ['name'],
    generators: ['name', 'facility_name', 'mrets_id', 'state_province'],
    certificates: [
      'eligibilities',
      'thermal_resource',
      'generator',
      'serial_number_base',
      'vintage_date',
      'carbon_pathways',
      'generator_fuel',
      'generation_entry',
    ],
    feedstock: ['id', 'description'],
    generation_entry: ['reported_source'],
    generator_fuel: ['feedstock'],
    transaction_detail: ['user_transaction', 'carbon_pathway'],
    user_transaction: ['retired_quarter'],
  },
};

export const RESERVED_CERTIFICATES_INCLUDES = {
  rec: {
    includeAll: [
      'account',
      'account.organization',
      'account.program',
      'certificate',
      'certificate.fuel_type',
      'certificate.generator',
      'certificate.generator.generator_fuels.eligibility_bonds.eligibility',
      'certificate.generation_entry',
      'certificate.eligibilities',
      'transaction_detail.user_transaction',
      'etag_quantities',
      'etag_quantities.etag',
    ],
    includeUniqueBy: {
      'accounts.name': ['account'],
      'generators.mrets_id': ['certificate.generator'],
      'generators.name': ['certificate.generator'],
      'generators.facility_name': ['certificate.generator'],
      'programs.name': ['account.program'],
      'generators.state_province': ['certificate.generator'],
      'fuel_types.name': ['certificate.fuel_type'],
      'eligibilities.long_name': ['certificate.eligibilities'],
      'user_transactions.retirement_type': [
        'transaction_detail.user_transaction',
      ],
      'user_transactions.retirement_reason': [
        'transaction_detail.user_transaction',
      ],
      'user_transactions.retired_to': ['transaction_detail.user_transaction'],
      'user_transactions.compliance_period': [
        'transaction_detail.user_transaction',
      ],
      'organizations.name': ['account.organization'],
    },
  },
  rtc: {
    includeAll: [
      'account',
      'account.organization',
      'account.program',
      'certificate',
      'certificate.carbon_pathways',
      'certificate.thermal_resource',
      'certificate.generator',
      'certificate.eligibilities',
      'certificate.generation_entry',
      'certificate.generator_fuel',
      'certificate.generator_fuel.feedstock',
      'transaction_detail.user_transaction',
      'transaction_detail.carbon_pathway',
    ],
    includeUniqueBy: {
      'accounts.name': ['account'],
      'generators.mrets_id': ['certificate.generator'],
      'generators.name': ['certificate.generator'],
      'generators.facility_name': ['certificate.generator'],
      'programs.name': ['account.program'],
      'generators.state_province': ['certificate.generator'],
      'fuel_types.name': ['certificate.thermal_resource'],
      'fuel_sources.description': ['certificate.generator_fuel.feedstock'],
      'transaction_detail.carbon_pathway.name': [
        'transaction_detail.carbon_pathway',
      ],
      'transaction_detail.carbon_pathways.tool_name': [
        'transaction_detail.carbon_pathway',
      ],
      'transaction_detail.carbon_pathway.document_name': [
        'transaction_detail.carbon_pathway',
      ],
      'eligibilities.long_name': ['certificate.eligibilities'],
      'user_transactions.retirement_type': [
        'transaction_detail.user_transaction',
      ],
      'user_transactions.retirement_reason': [
        'transaction_detail.user_transaction',
      ],
      'user_transactions.retired_to': ['transaction_detail.user_transaction'],
      'user_transactions.compliance_period': [
        'transaction_detail.user_transaction',
      ],
      'organizations.name': ['account.organization'],
    },
  },
};

export const RESERVED_CERTIFICATES_FIELDS = {
  rec: {
    accounts: ['name', 'id', 'short_id', 'organization', 'program'],
    eligibilities: ['short_name', 'long_name', 'slug'],
    fuel_types: ['name'],
    programs: ['name'],
    generators: [
      'name',
      'facility_name',
      'mrets_id',
      'state_province',
      'generator_fuels',
    ],
    certificates: [
      'eligibilities',
      'fuel_type',
      'generator',
      'generation_entry',
      'serial_number_base',
      'vintage_date',
    ],
    generation_entry: ['reported_source'],
  },
  rtc: {
    accounts: ['name', 'id', 'short_id', 'organization', 'program'],
    eligibilities: ['short_name', 'long_name', 'slug'],
    carbon_pathways: [
      'id',
      'name',
      'tool_name',
      'carbon_intensity',
      'start_date',
      'end_date',
      'grams_co2_per_dth',
      'document_url',
      'document_name',
      'endpoint',
      'endpoint_description',
      'injection_point',
    ],
    thermal_resources: ['name'],
    programs: ['name'],
    generators: ['name', 'facility_name', 'mrets_id', 'state_province'],
    certificates: [
      'eligibilities',
      'thermal_resource',
      'generator',
      'serial_number_base',
      'vintage_date',
      'carbon_pathways',
      'generation_entry',
      'generator_fuel',
    ],
    feedstock: ['id', 'description'],
    generation_entry: ['reported_source'],
    generator_fuel: ['feedstock'],
    transaction_detail: ['carbon_pathway', 'user_transaction'],
    user_transaction: ['retired_quarter'],
  },
};

export const CERTIFICATES_TABLE_CONFIG = {
  active: {
    includes: ACTIVE_CERTIFICATES_INCLUDES,
    fields: ACTIVE_CERTIFICATES_FIELDS,
  },
  retirement: {
    includes: RETIREMENT_CERTIFICATES_INCLUDES,
    fields: RETIREMENT_CERTIFICATES_FIELDS,
  },
  reserved: {
    includes: RESERVED_CERTIFICATES_INCLUDES,
    fields: RESERVED_CERTIFICATES_FIELDS,
  },
  all: {
    includes: ACTIVE_CERTIFICATES_INCLUDES,
    fields: ACTIVE_CERTIFICATES_FIELDS,
  },
};

export const GENERATOR_FUELS = {
  rec: {
    includeAll: [
      'generator.owner',
      'generator.reporting_entity',
      'fuel_source.fuel_type',
      'eligibilities',
      'eligibility_bonds.eligibility',
      'generator.organization',
    ],
    includeUniqueBy: {
      'generators.county': ['generator'],
      'generators.name': ['generator'],
      'generators.mrets_id': ['generator'],
      'generators.ownership_type': ['generator'],
      'generators.state_province': ['generator'],
      'generator_rec_details.wi_rrc_unit_id': ['generator'],
      'contacts.name': ['generator.owner'],
      'fuel_types.name': ['fuel_source.fuel_type'],
      'fuel_sources.description': ['fuel_source'],
      'eligibilities.long_name': ['eligibilities'],
      'eligibilities.slug': ['eligibilities'],
      'reporting_entities.name': ['generator.reporting_entity'],
      'organizations.name': ['generator.organization'],
    },
  },
  rtc: {
    includeAll: [
      'generator.owner',
      'generator.reporting_entity',
      'feedstock.thermal_resource',
      'eligibilities',
      'eligibility_bonds.eligibility',
      'generator.organization',
    ],
    includeUniqueBy: {
      'generators.county': ['generator'],
      'generators.name': ['generator'],
      'generators.mrets_id': ['generator'],
      'generators.ownership_type': ['generator'],
      'generators.state_province': ['generator'],
      'generator_rec_details.wi_rrc_unit_id': ['generator'],
      'contacts.name': ['generator.owner'],
      'fuel_types.name': ['feedstock.thermal_resource'],
      'fuel_sources.description': ['feedstock'],
      'eligibilities.long_name': ['eligibilities'],
      'eligibilities.slug': ['eligibilities'],
      'reporting_entities.name': ['generator.reporting_entity'],
      'organizations.name': ['generator.organization'],
    },
  },
};

export const RECURRING_TRANSFER = {
  rec: {
    includeAll: [
      'organization',
      'generator_fuel.fuel_source.fuel_type',
      'user',
      'generator_fuel.generator.issue_to_account',
      'recurring_transfer_destinations',
    ],
    includeUniqueBy: {
      'generators.name': ['generator_fuel.generator'],
      'generators.facility_name': ['generator_fuel.generator'],
      'generators.mrets_id': ['generator_fuel.generator'],
      'fuel_types.name': ['generator_fuel.fuel_source.fuel_type'],
      'fuel_sources.description': ['generator_fuel.fuel_source'],
      'organizations.name': ['organization'],
      'accounts.name': ['generator_fuel.generator.issue_to_account'],
      'destinations.name': ['recurring_transfer_destinations'],
    },
  },
  rtc: {
    includeAll: [
      'organization',
      'generator_fuel.feedstock.thermal_resource',
      'user',
      'generator_fuel.generator.issue_to_account',
      'recurring_transfer_destinations',
    ],
    includeUniqueBy: {
      'generators.name': ['generator_fuel.generator'],
      'generators.facility_name': ['generator_fuel.generator'],
      'generators.mrets_id': ['generator_fuel.generator'],
      'fuel_types.name': ['generator_fuel.feedstock.thermal_resource'],
      'fuel_sources.description': ['generator_fuel.feedstock'],
      'organizations.name': ['organization'],
      'accounts.name': ['generator_fuel.generator.issue_to_account'],
      'destinations.name': ['recurring_transfer_destinations'],
    },
  },
};

export const GENERATOR_FUELS_FIELDS = {
  rec: {
    contacts: ['name'],
    organizations: ['name', 'friendly_id'],
    generator_fuels: [
      'fuel_source',
      'eligibilities',
      'generator',
      'eligibility_bonds',
    ],
    fuel_source: ['fuel_type'],
    eligibilities: ['short_name', 'long_name', 'slug'],
    eligibility_bonds: [
      'start_date',
      'verified_date',
      'good_thru_date',
      'eligibility',
      'eligible',
      'certification_number',
      'details',
    ],
  },
  rtc: {
    contacts: ['name'],
    organizations: ['name', 'friendly_id'],
    generator_fuels: [
      'feedstock',
      'eligibilities',
      'generator',
      'eligibility_bonds',
    ],
    feedstock: ['thermal_resource'],
    eligibilities: ['short_name', 'long_name', 'slug'],
    eligibility_bonds: [
      'start_date',
      'verified_date',
      'good_thru_date',
      'eligibility',
      'eligible',
      'certification_number',
      'details',
    ],
  },
};
export const RECURRING_TRANSFER_FIELDS = {
  rec: {
    generators: ['name', 'facility_name', 'issue_to_account', 'mrets_id'],
    fuel_types: ['name'],
    fuel_sources: ['description', 'fuel_type'],
    organizations: ['name'],
    accounts: ['name'],
    generator_fuels: ['generator', 'fuel_source'],
  },
  rtc: {
    generators: ['name', 'facility_name', 'issue_to_account', 'mrets_id'],
    thermal_resources: ['name'],
    feedstocks: ['description', 'thermal_resource'],
    organizations: ['name'],
    accounts: ['name'],
    generator_fuels: ['generator', 'feedstock'],
  },
};

export const USER_TRANSACTION = {
  rec: {
    includeAll: [
      'transaction_details',
      'transaction_details.certificate',
      'transaction_details.certificate.generator',
      'transaction_details.certificate.generator_fuel',
      'transaction_details.certificate.fuel_type',
      'transaction_details.certificate.generator_fuel.fuel_source',
      'transaction_details.certificate.generator_fuel.fuel_source.fuel_type',
      'transaction_details.certificate.eligibilities',
      'transaction_details.etag_quantities.etag',
      'transaction_details.to_organization',
      'transaction_details.from_organization',
    ],
  },
  rtc: {
    includeAll: [
      'transaction_details',
      'transaction_details.certificate',
      'transaction_details.certificate.generator',
      'transaction_details.certificate.generator_fuel',
      'transaction_details.certificate.generator_fuel.feedstock',
      'transaction_details.certificate.eligibilities',
      'transaction_details.to_organization',
      'transaction_details.from_organization',
    ],
  },
};

export const USER_TRANSACTION_FIELDS = {
  rec: {
    transaction_details: [
      'serial_number_start',
      'serial_number_end',
      'certificate',
      'quantity',
      'to_organization',
      'from_organization',
      'etag_quantities',
    ],
    certificates: [
      'serial_number_base',
      'serial_number_start',
      'serial_number_end',
      'generator',
      'generator_fuel',
      'vintage_date',
      'eligibilities',
      'fuel_type',
    ],
    organizations: ['name'],
    generators: ['name', 'mrets_id', 'state_province', 'facility_name'],
    generator_fuel: ['fuel_source'],
    fuel_sources: ['description', 'fuel_type'],
    fuel_type: ['name'],
    etag_quantities: ['quantity', 'status', 'etag'],
    etag: ['etag_id', 'start_date', 'stop_date'],
  },
  rtc: {
    transaction_details: [
      'serial_number_start',
      'serial_number_end',
      'certificate',
      'quantity',
      'to_organization',
      'from_organization',
    ],
    certificates: [
      'serial_number_base',
      'serial_number_start',
      'serial_number_end',
      'generator',
      'generator_fuel',
      'vintage_date',
      'eligibilities',
    ],
    organizations: ['name'],
    generator_fuel: ['feedstock'],
    feedstocks: ['description'],
  },
};
