export const RADIO_FIELD_BOOLEAN_OPTIONS = [
  {
    value: '1',
    label: 'Yes',
  },
  {
    value: '0',
    label: 'No',
  },
];
