import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '../../../hooks/user';
import filterPermissions from '../../../utils/filterPermissions';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';

const Permissions = ({ children, canBeDisplayed, ...permissionsConfig }) => {
  if (!canBeDisplayed) {
    return null;
  }

  const { user } = useUser();

  const { featureFlags } = useFeatureFlags();
  const isValid = filterPermissions(
    [permissionsConfig],
    user,
    featureFlags,
  ).length;

  if (!isValid) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <React.Fragment>{children}</React.Fragment>;
};

Permissions.defaultProps = {
  canBeDisplayed: true,
};

Permissions.propTypes = {
  children: PropTypes.node.isRequired,
  canBeDisplayed: PropTypes.bool,
};

export default Permissions;
