import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';
import { stringToDate } from '../../../utils/helpers';
import Button from '../Button';
import TextField from '../TextField';

import './NotesList.scss';

const NotesList = ({ data, edit, onAdd }) => {
  const [inputState, setInputState] = useState('');
  const [notesState, setNotesState] = useState([]);

  useEffect(() => {
    setNotesState(Array.isArray(data) ? [...data] : []);
  }, [data]);

  const handleChange = ({ target: { value } }) => {
    setInputState(value);
  };

  const handleClick = () => {
    if (inputState) {
      const newData = [...notesState, { content: inputState }];

      setInputState('');
      onAdd(newData);
      setNotesState(newData);
    }
  };

  return (
    <div className="NotesList NotesList__Container">
      <h4 className="Header">Notes</h4>
      <Card variant="outlined">
        <CardContent>
          <div className="NotesList__List">
            {notesState?.map((note, index) => {
              return (
                <React.Fragment key={`${note.created_at}_${index.toString()}`}>
                  <li className="NotesList__Note">
                    {note.content}
                    {!!note.created_at && (
                      <div className="NotesList__Details">
                        <div>{note.user?.email || ''}</div>
                        <div>{String(stringToDate(note.created_at))}</div>
                      </div>
                    )}
                  </li>
                </React.Fragment>
              );
            })}
          </div>
          {edit && (
            <div className="NotesList__Add">
              <TextField
                onChange={handleChange}
                value={inputState}
                className="NotesList__Input"
                placeholder="Add a note"
                inputProps={{ 'data-testid': 'content-input' }}
              />
              <Button
                className="NotesList__AddButton"
                variant="primary"
                size="large"
                onClick={handleClick}
              >
                Add Note
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

NotesList.defaultProps = {
  data: [],
  edit: false,
  onAdd: () => {},
};

NotesList.propTypes = {
  data: PropTypes.array,
  edit: PropTypes.bool,
  onAdd: PropTypes.func,
};

export default NotesList;
