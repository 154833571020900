import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, ClickAwayListener, Popover } from '@mui/material';
import classnames from 'classnames';

import { useAppTheme } from '../../../hooks/theme';

import './FieldPopover.scss';

const FieldPopover = ({
  label,
  onOpen,
  onClose,
  component: ButtonComponent,
  componentProps,
  popoverProps,
  className,
  children,
  icon,
  valueSelected,
}) => {
  const { name: themeName } = useAppTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenPopover = (event) => {
    event.stopPropagation();
    const newAnchorEl = event.currentTarget;
    setAnchorEl(newAnchorEl);
    onOpen(newAnchorEl);
  };

  const onClosePopover = () => {
    setAnchorEl(null);
    if (onClose) {
      onClose();
    }
  };

  const paperClassName = `FieldPopover__Popover__Paper ${className} ${themeName}`;

  return (
    <div className="FieldPopover">
      <ButtonComponent
        className="FieldPopover__Button"
        data-testid="FieldPopover__Button"
        onClick={onOpenPopover}
        {...componentProps}
      >
        <div
          className={classnames('FieldPopover__ButtonLabel', {
            'FieldPopover__ButtonLabel--selected': valueSelected,
          })}
        >
          {label || 'Select...'}
        </div>
        {!!icon && <div className="FieldPopover__Icon">{icon}</div>}
      </ButtonComponent>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={`${className}__Popover FieldPopover__Popover`}
        PaperProps={{
          className: paperClassName,
        }}
        {...popoverProps}
      >
        <ClickAwayListener onClickAway={onClosePopover}>
          <div className="FieldPopover__Content">
            {React.cloneElement(children, { onClose: onClosePopover })}
          </div>
        </ClickAwayListener>
      </Popover>
    </div>
  );
};

FieldPopover.defaultProps = {
  label: null,
  component: ButtonBase,
  componentProps: {},
  popoverProps: {},
  className: 'FieldPopover__Popover__Paper--default',
  onOpen: () => {},
  onClose: () => {},
  icon: null,
  valueSelected: false,
};

FieldPopover.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  component: PropTypes.any,
  componentProps: PropTypes.object,
  popoverProps: PropTypes.object,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  valueSelected: PropTypes.bool,
};

export default FieldPopover;
