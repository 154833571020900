/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';

import './TooltipLabel.scss';

const TooltipLabel = ({
  tooltip,
  icon: TooltipIcon,
  label,
  className,
  ...otherProps
}) => {
  const tooltipClassName = classNames('TooltipLabel', className);

  return (
    <div className={tooltipClassName}>
      {!!label && <div className="TooltipLabel__Label">{label}</div>}
      <Tooltip
        PopperProps={{
          className: 'TooltipLabel__Popper',
        }}
        className="TooltipLabel__Tooltip"
        title={
          <span
            className="TooltipLabel__Tooltip__Content"
            dangerouslySetInnerHTML={{ __html: tooltip }}
          />
        }
        placement="top"
        leaveDelay={200}
        {...otherProps}
      >
        <TooltipIcon />
      </Tooltip>
    </div>
  );
};

TooltipLabel.defaultProps = {
  className: '',
  label: null,
  icon: InfoIcon,
};

TooltipLabel.propTypes = {
  tooltip: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.object,
};

export default TooltipLabel;
