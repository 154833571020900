import account from './account';
import balancingAuthority from './balancingAuthority';
import carbonPathway from '../rtc/carbonPathway';
import certificate from './certificate';
import certificateQuantity from './certificateQuantity';
import certificateHourlyData from './certificateHourlyData';
import contact from './contact';
import eligibility from './eligibility';
import eligibilityBond from './eligibilityBond';
import etag from './etag';
import etagMatch from './etagMatch';
import etagQuantity from './etagQuantity';
import featureFlag from './featureFlag';
import fuelType from './fuelType';
import generationEntry from './generationEntry';
import generationValidationCurve from './generationValidationCurve';
import generationUploadReport from './generationUploadReport';
import generator from './generator';
import generatorAggregate from './generatorAggregate';
import generatorDocument from './generatorDocument';
import interconnectedUtility from './interconnectedUtility';
import notification from './notification.js';
import document from './document';
import organization from './organization';
import organizationDocument from './organizationDocument';
import organizationAccountType from './organizationAccountType';
import user from './user';
import userOrganization from './userOrganization';
import transactionDetail from './transactionDetail';
import userTransaction from './userTransaction';
import fuelSource from './fuelSource';
import generatorFuel from './generatorFuel.js';
import program from './program.js';
import programFuel from './programFuel';
import invoice from './invoice.js';
import recurringTransfer from './recurringTransfer';
import issueToAccount from './issueToAccount';
import recurringTransferDestination from './recurringTransferDestination';
import retirementOptions from './retirementOptions';
import programParticipantInvite from './programParticipantInvite';
import notes from './notes';
import resourceType from './resourceType';
import apiKey from './apiKey';
import calc from './calc';
import pseCode from './pseCode';
import reportExportRequest from './reportExportRequest';
import ticket from './ticket';
import ticketMessage from './ticketMessage';
import ticketDocument from './ticketDocument';
import generatorFuelRemainderAdjustment from './generatorFuelRemainderAdjustment';

const models = {
  account,
  balancingAuthority,
  carbonPathway,
  certificate,
  certificateQuantity,
  certificateHourlyData,
  contact,
  eligibility,
  eligibilityBond,
  etag,
  etagMatch,
  etagQuantity,
  featureFlag,
  fuelType,
  notification,
  document,
  generator,
  generatorAggregate,
  generatorDocument,
  generationEntry,
  generationValidationCurve,
  generationUploadReport,
  interconnectedUtility,
  organization,
  organizationDocument,
  organizationAccountType,
  user,
  userOrganization,
  transactionDetail,
  userTransaction,
  fuelSource,
  generatorFuel,
  program,
  invoice,
  recurringTransfer,
  issueToAccount,
  recurringTransferDestination,
  retirementOptions,
  programFuel,
  programParticipantInvite,
  notes,
  resourceType,
  apiKey,
  calc,
  pseCode,
  reportExportRequest,
  ticket,
  ticketMessage,
  ticketDocument,
  generatorFuelRemainderAdjustment,
};

export default models;
