import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Radio, FormHelperText } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningTwoTone';
import TooltipLabel from '../TooltipLabel';
import { RADIO_FIELD_BOOLEAN_OPTIONS } from './RadioField.constants';

import './RadioField.scss';

const RadioField = ({
  id,
  label,
  disabled,
  name,
  onChange,
  options,
  boolean,
  required,
  error,
  value,
  helperText,
  tooltip,
  warningMessage,
  ...rest
}) => {
  const radioOptions =
    boolean && !options.length ? RADIO_FIELD_BOOLEAN_OPTIONS : options;

  const onRadioChange = ({ target: { value: eventValue } }) => {
    let fixedValue = eventValue;
    if (boolean) {
      fixedValue = eventValue === RADIO_FIELD_BOOLEAN_OPTIONS[0].value;
    }

    onChange({ target: { value: fixedValue } });
  };

  const fixedValue = useMemo(() => {
    let newValue = value;

    if (boolean) {
      newValue = value
        ? RADIO_FIELD_BOOLEAN_OPTIONS[0].value
        : RADIO_FIELD_BOOLEAN_OPTIONS[1].value;
    }

    return newValue;
  }, [value]);

  const className = classnames('MRets__RadioField', {
    'MRets__RadioField--error': error,
  });

  const labelClassName = classnames('MRets__RadioField__Label', {
    'MRets__RadioField__Label--tooltip': tooltip,
    'MRets__RadioField__Label--warningMessage': warningMessage,
    'MRets__RadioField__Label--tooltip-and-warningMessage':
      tooltip && warningMessage,
  });

  return (
    <div className={className}>
      {label && (
        <h5 className={labelClassName}>
          {!tooltip && label}
          {!!tooltip && <TooltipLabel tooltip={tooltip} label={label} />}
          {required && <span className="MRets__RadioField__Required"> *</span>}
          {!!warningMessage && (
            <TooltipLabel
              className="MRets__RadioField__WarningMessage"
              tooltip={warningMessage}
              icon={WarningIcon}
            />
          )}
        </h5>
      )}
      <div className="MRets__RadioField__Options">
        {radioOptions.map((option) => {
          const radioId = `${name || 'radio'}-${option.value}`.replace(
            /[^\w]+/g,
            '-',
          );

          return (
            <label
              key={option.value}
              htmlFor={radioId}
              className="dib mb0 mr3-5 pointer MRets__RadioField__Option"
            >
              <Radio
                checked={fixedValue === option.value}
                onChange={onRadioChange}
                value={option.value}
                name={name}
                disabled={disabled}
                {...rest}
                id={radioId}
              />
              {option.label}
            </label>
          );
        })}
      </div>

      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
};

RadioField.defaultProps = {
  id: '',
  disabled: false,
  required: false,
  error: false,
  boolean: false,
  options: [],
  value: undefined,
  helperText: undefined,
  label: undefined,
  tooltip: undefined,
  warningMessage: null,
  onChange: () => {},
};

RadioField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  boolean: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.node.isRequired,
  label: PropTypes.node,
  tooltip: PropTypes.string,
  warningMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default RadioField;
