import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '../TextField';

import './SearchField.scss';

const SearchField = (props) => {
  return (
    <TextField
      className="MRets__SearchField"
      variant="outlined"
      type="search"
      unit={<SearchIcon />}
      fullWidth
      {...props}
    />
  );
};

export default SearchField;
