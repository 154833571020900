import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@mui/material';
import PhoneInput from 'react-phone-input-2';

import TooltipLabel from '../TooltipLabel';

const PhoneField = ({
  label,
  value,
  defaultCountry,
  disabled,
  onChange,
  required,
  helperText,
  tooltip,
}) => {
  const onPhoneChange = (eventValue, data, event, formattedValue) => {
    onChange({ target: { value: eventValue, data, event, formattedValue } });
  };

  return (
    <div className="MRets__PhoneField">
      {label && (
        <h5 className="MRets__PhoneField__Label f5 lh-copy dark-gray fw7 mb1">
          {!tooltip && label}
          {!!tooltip && <TooltipLabel tooltip={tooltip} label={label} />}
          {required && <span className="MRets__RadioField__Required"> *</span>}
        </h5>
      )}
      <div className="MRets__PhoneField__Input">
        <PhoneInput
          country={defaultCountry}
          value={value}
          onChange={onPhoneChange}
          countryCodeEditable={false}
          disabled={disabled}
        />
      </div>

      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
};

PhoneField.defaultProps = {
  disabled: false,
  required: false,
  helperText: undefined,
  label: undefined,
  tooltip: undefined,
  onChange: () => {},
  defaultCountry: 'us',
};

PhoneField.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  defaultCountry: PropTypes.string,
};

export default PhoneField;
