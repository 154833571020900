import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { getErrorMessageFromResponse } from '../utils/helpers';

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onNotify = (message, variant = 'default', otherOptions = {}) => {
    return enqueueSnackbar(message, {
      variant,
      className: `FlashNotification FlashNotification--${variant}`,
      autoHideDuration: 4000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      action: (key) => {
        const onDismissClick = () => {
          closeSnackbar(key);
        };

        return (
          <IconButton
            onClick={onDismissClick}
            className="FlashNotification__Dismiss"
            size="small"
          >
            <CloseIcon />
          </IconButton>
        );
      },
      ...otherOptions,
    });
  };

  const onSuccess = (message, otherOptions = {}) => {
    return onNotify(message, 'success', otherOptions);
  };

  const onError = (message, otherOptions = {}) => {
    return onNotify(message, 'error', otherOptions);
  };

  const onResponseError = (errorResponse, otherOptions = {}) => {
    const message = getErrorMessageFromResponse(errorResponse);
    return onNotify(message, 'error', otherOptions);
  };

  const onWarning = (message, otherOptions = {}) => {
    return onNotify(message, 'warning', otherOptions);
  };

  const onInfo = (message, otherOptions = {}) => {
    return onNotify(message, 'info', otherOptions);
  };

  const closeNotification = (key) => {
    closeSnackbar(key);
  };

  return {
    onNotify,
    onSuccess,
    onError,
    onResponseError,
    onWarning,
    onInfo,
    closeNotification,
  };
};
