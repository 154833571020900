import WarningIcon from '@mui/icons-material/WarningRounded';

const BILLING_PERSISTENT_NOTIFICATION = {
  icon: WarningIcon,
  message:
    'One or more of your invoices is overdue. Please pay to avoid any additional fees and to avoid account inactivation.',
  ctaLabel: 'Check Billing Info',
  ctaProps: {
    href: '/organization/billing-info',
  },
};

export const PERSISTENT_NOTIFICATIONS = {
  billing: BILLING_PERSISTENT_NOTIFICATION,
  default: BILLING_PERSISTENT_NOTIFICATION,
};
