import React from 'react';

const GreenCheck = () => {
  return (
    <svg
      className="db mr3 flex-auto-0-0 pop-in-subtle"
      aria-hidden="true"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0" />
        <path
          d="M10.83 13.3l-2.12-2.122-1.38 1.38 3.5 3.57 6.365-6.365L15.78 8.35l-4.95 4.95zM11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM4 12c0-4.42 3.58-8 8-8s8 3.58 8 8-3.58 8-8 8-8-3.58-8-8z"
          fill="#49A071"
        />
      </g>
    </svg>
  );
};

export default GreenCheck;
