import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Autocomplete,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningTwoTone';
import TooltipLabel from '../TooltipLabel';
import { useI18N } from '../../../hooks/i18n';

import './SelectField.scss';
import TextField from '../TextField';

const SelectField = ({
  label,
  options,
  config,
  helperText,
  pickOneOption,
  autoSelect,
  value,
  required,
  error,
  tooltip,
  warningMessage,
  autocomplete,
  showPickOneOptionAfterSelect,
  children,
  ...otherProps
}) => {
  useEffect(() => {
    if (autoSelect && options.length === 1) {
      const autoSelectedOption = options[0];
      otherProps.onChange({
        target: {
          value:
            autoSelectedOption[config.valueKey] ||
            autoSelectedOption[config.fallbackKey],
        },
      });
    }
  }, []);

  const { i18nText } = useI18N();

  const labelText = useMemo(
    () => (label ? i18nText(label, {}, label) : ''),
    [label],
  );

  const labelClassName = classnames('MRets__SelectField__Label', {
    'MRets__SelectField__Label--tooltip': tooltip,
    'MRets__SelectField__Label--warningMessage': warningMessage,
    'MRets__SelectField__Label--tooltip-and-warningMessage':
      tooltip && warningMessage,
  });

  const selectFieldClassName = classnames('MRets__SelectField', {
    'MRets__SelectField--tooltip': tooltip,
    'MRets__SelectField--warningMessage': warningMessage,
    'MRets__SelectField--tooltip-and-warningMessage': tooltip && warningMessage,
  });

  const onAutocompleteChange = (_, autocompleteValue) => {
    otherProps.onChange({ target: { value: autocompleteValue } });
  };

  return (
    <FormControl
      className={selectFieldClassName}
      variant="outlined"
      fullWidth
      error={error}
    >
      {!!labelText && (
        <InputLabel
          className={labelClassName}
          shrink
          style={{ pointerEvents: 'auto' }}
        >
          {!tooltip && labelText}
          {!!tooltip && <TooltipLabel tooltip={tooltip} label={labelText} />}
          {required && <span className="MRets__SelectField__Required"> *</span>}
          {!!warningMessage && (
            <TooltipLabel
              className="MRets__SelectField__WarningMessage"
              tooltip={warningMessage}
              icon={WarningIcon}
            />
          )}
        </InputLabel>
      )}
      {!autocomplete && (
        <Select native value={value} {...otherProps}>
          {!!pickOneOption && (!value || showPickOneOptionAfterSelect) && (
            <option key="" value="">
              {pickOneOption}
            </option>
          )}
          {options.map((option) => {
            const optionValue =
              option[config.valueKey] || option[config.fallbackKey];
            const optionKey = `${option[config.valueKey]}_${
              option[config.fallbackKey]
            }`;
            const optionLabel =
              option[config.labelKey] || option[config.labelFallbackKey];
            return (
              <option
                key={optionKey}
                value={optionValue}
                disabled={option.disabled}
              >
                {optionLabel ? i18nText(optionLabel) : optionLabel}
              </option>
            );
          })}
        </Select>
      )}
      {autocomplete && (
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option[config.labelKey] || ''}
          renderInput={(params) => <TextField {...params} />}
          value={value || null}
          {...otherProps}
          onChange={onAutocompleteChange}
        />
      )}
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      {children}
    </FormControl>
  );
};

SelectField.defaultProps = {
  options: [],
  helperText: null,
  label: null,
  pickOneOption: true,
  autoSelect: false,
  value: '',
  error: false,
  required: false,
  autocomplete: false,
  config: {
    valueKey: 'value',
    labelKey: 'label',
    fallbackKey: 'id',
  },
  tooltip: null,
  warningMessage: null,
  showPickOneOptionAfterSelect: false,
  children: null,
};

SelectField.propTypes = {
  options: PropTypes.array,
  helperText: PropTypes.any,
  pickOneOption: PropTypes.any,
  autoSelect: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  error: PropTypes.bool,
  required: PropTypes.bool,
  autocomplete: PropTypes.bool,
  config: PropTypes.object,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  warningMessage: PropTypes.string,
  showPickOneOptionAfterSelect: PropTypes.bool,
  children: PropTypes.node,
};

export default SelectField;
