import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import './Accordion.scss';

const AccordionComponent = (props) => {
  const { summary, details } = props;
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      className="AccordionComponent"
      expanded={expanded}
      onChange={toggleExpanded}
      disableGutters
      elevation={0}
      square
    >
      <AccordionSummary
        className="AccordionComponent__Summary"
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails className="AccordionComponent__Details">
        {details}
      </AccordionDetails>
    </Accordion>
  );
};

AccordionComponent.defaultProps = {
  summary: null,
  details: null,
};

AccordionComponent.propTypes = {
  summary: PropTypes.node,
  details: PropTypes.node,
};

export default AccordionComponent;
