import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUser } from '../../../hooks/user';
import SubNavComponent from './SubNav.component';
import filterPermissions from '../../../utils/filterPermissions';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';

const SubNavContainer = (props) => {
  const { navItems } = props;
  const { user } = useUser();
  const { path } = useRouteMatch();
  const { featureFlags } = useFeatureFlags();

  const navList = filterPermissions(navItems, user, featureFlags);

  return <SubNavComponent path={path} {...props} navItems={navList} />;
};

SubNavContainer.propTypes = {
  navItems: PropTypes.array.isRequired,
};

export default SubNavContainer;
