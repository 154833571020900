import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from '../../../shared';

import './PersistentNotification.scss';

const PersistentNotification = ({
  message,
  variant,
  icon: IconComponent,
  ctaProps,
  ctaLabel,
}) => {
  const className = classnames(
    'PersistentNotification',
    `PersistentNotification--${variant}`,
    {
      'PersistentNotification--without-icon': !IconComponent,
      'PersistentNotification--without-cta': !ctaLabel,
    },
  );

  return (
    <div className={className}>
      {!!IconComponent && (
        <div className="PersistentNotification__Icon">
          <IconComponent />
        </div>
      )}
      <div className="PersistentNotification__Message">{message}</div>
      {!!ctaLabel && (
        <div className="PersistentNotification__CTAButton">
          <Button {...ctaProps}>{ctaLabel}</Button>
        </div>
      )}
    </div>
  );
};

PersistentNotification.defaultProps = {
  ctaProps: {},
  ctaLabel: null,
  icon: null,
  variant: 'danger',
};

PersistentNotification.propTypes = {
  message: PropTypes.string.isRequired,
  ctaProps: PropTypes.object,
  ctaLabel: PropTypes.string,
  icon: PropTypes.func,
  variant: PropTypes.string,
};

export default PersistentNotification;
