import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  format,
  isAfter,
  isBefore,
  isSameMonth,
  isWithinInterval,
} from 'date-fns';
import { Button, IconButton, Select } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const YEARS = Array(...Array(new Date().getFullYear() - 2005)).map(
  (_, i) => `${i + 2006}`,
);

const MonthPicker = ({
  isRange,
  isFulfilled,
  value,
  onChange,
  additionalYears,
  startYear,
  isDisabled,
}) => {
  const lastYearIndex = YEARS.length - 1;
  const years = YEARS.concat(
    Array(...Array(additionalYears)).map(
      (_, i) => `${parseInt(YEARS[lastYearIndex], 10) + 1 + i}`,
    ),
  );
  const startYearIndex = startYear ? years.indexOf(startYear) : lastYearIndex;
  const [currentYearIndex, setCurrentYearIndex] = useState(startYearIndex);

  const onYearChange = (event) => {
    setCurrentYearIndex(parseInt(event.target.value, 10));
  };

  const onPrevYearClick = () => {
    let newYearIndex = currentYearIndex - 1;
    newYearIndex = newYearIndex < 0 ? 0 : newYearIndex;
    setCurrentYearIndex(newYearIndex);
  };

  const onNextYearClick = () => {
    let newYearIndex = currentYearIndex + 1;
    newYearIndex =
      newYearIndex > years.length - 1 ? years.length - 1 : newYearIndex;

    setCurrentYearIndex(newYearIndex);
  };

  const onMonthClick = (monthDate) => (event) => {
    event.stopPropagation();
    let newValue = monthDate;

    if (isRange) {
      const [startDate = null, endDate = null] = value;

      const isBeforeStartDate = startDate && isBefore(monthDate, startDate);
      const newStartDate =
        !startDate || isBeforeStartDate ? monthDate : startDate;

      const isAfterEndDate = endDate && isAfter(monthDate, endDate);
      const newEndDate =
        startDate && (!endDate || isAfterEndDate) ? monthDate : endDate;

      newValue = [newStartDate, newEndDate];

      if (startDate && endDate && !isBeforeStartDate && !isAfterEndDate) {
        newValue = [monthDate, null];
      }
    }

    onChange({ target: { value: newValue } });
  };

  const monthsArray = useMemo(() => {
    const months = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 12; i++) {
      const monthDate = new Date(years[currentYearIndex], i, 1);
      months.push(monthDate);
    }

    return months;
  }, [currentYearIndex]);

  const dateInterval = isRange &&
    isFulfilled && { start: value[0], end: value[1] };

  return (
    <div className="MonthPicker">
      <div className="MonthPicker__YearSelector">
        <IconButton onClick={onPrevYearClick} size="large">
          <ChevronLeftIcon />
        </IconButton>
        <Select
          variant="standard"
          native
          value={currentYearIndex}
          onChange={onYearChange}
        >
          {years.map((year, index) => (
            <option key={year} value={index}>
              {year}
            </option>
          ))}
        </Select>
        <IconButton onClick={onNextYearClick} size="large">
          <ChevronRightIcon />
        </IconButton>
      </div>

      <div className="MonthPicker__MonthSelector">
        {monthsArray.map((date) => (
          <Button
            key={date.getMonth()}
            variant="outlined"
            className={classnames('MonthPicker__MonthButton', {
              'MonthPicker__MonthButton--selected': isRange
                ? value &&
                  value.find(
                    (valueDate) => valueDate && isSameMonth(valueDate, date),
                  )
                : value && isSameMonth(value, date),
              'MonthPicker__MonthButton--included': dateInterval
                ? isWithinInterval(date, dateInterval)
                : false,
            })}
            onClick={onMonthClick(date)}
            disabled={isDisabled}
          >
            {format(date, 'MMM')}
          </Button>
        ))}
      </div>
    </div>
  );
};

MonthPicker.defaultProps = {
  isRange: false,
  isFulfilled: false,
  value: null,
  additionalYears: 0,
  startYear: null,
  isDisabled: false,
};

MonthPicker.propTypes = {
  isRange: PropTypes.bool,
  isFulfilled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  additionalYears: PropTypes.number,
  startYear: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default MonthPicker;
