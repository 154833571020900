import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import FileField from '../FileField';

import './FileUpload.scss';

const FileUpload = ({
  file,
  label,
  helperText,
  onChange,
  onClearDocument,
  readOnly,
  ...otherProps
}) => {
  return (
    <div className="MRets__FileUpload">
      {!file && (
        <FileField
          label={label}
          onChange={onChange}
          helperText={helperText}
          multiple={false}
          {...otherProps}
        />
      )}
      {!!file && (
        <div className="FileUpload__SelectedFile">
          <div className="SelectedFile__Label">Selected file</div>
          <div className="SelectedFile__File">
            <span className="File__Name">{file.name}</span>
            {!readOnly && (
              <React.Fragment>
                <IconButton onClick={onClearDocument} size="large">
                  <RemoveCircleOutlineIcon className="File__RemoveIcon" />
                </IconButton>
                <span className="File__RemoveText">Remove file</span>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

FileUpload.defaultProps = {
  file: null,
  helperText: undefined,
  label: undefined,
  readOnly: false,
  onChange: () => {},
  onClearDocument: () => {},
};

FileUpload.propTypes = {
  file: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onClearDocument: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default FileUpload;
