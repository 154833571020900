export const readImageFile = async (file) => {
  return new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line no-undef
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };

      reader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
};
