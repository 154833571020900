/* eslint-disable no-unused-vars */
import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import FieldPopover from '../FieldPopover';
import GenerationUploadButtonReducer, {
  INITIAL_STATE,
  updateErrorAction,
  setInitialStateAction,
  updateCsvFileAction,
  updateIsLoadingAction,
  updateSuccessAction,
} from './UploadButton.reducer';
import UploadForm from './UploadForm.component';

import './UploadButton.scss';

const UploadButton = ({ onUpload, children, label, validFileTypes }) => {
  const [state, dispatch] = useReducer(
    GenerationUploadButtonReducer,
    INITIAL_STATE,
  );

  const onClosePopover = () => {
    dispatch(setInitialStateAction());
  };

  const onCsvFileSelect = ({ target: { files } }) => {
    dispatch(updateCsvFileAction(files[0]));
  };

  const onTryAgain = () => {
    dispatch(updateErrorAction(false));
    dispatch(updateCsvFileAction(null));
  };

  const onUploadClick = async () => {
    const formData = {
      file: state.csvFile,
    };

    dispatch(updateIsLoadingAction(true));
    try {
      const response = await onUpload(formData);
      let successMessage = 'Upload successful.';
      if (response.async) {
        successMessage =
          'Upload successful. We are processing your request and an email will be sent to you with a report of uploaded items.';
      }
      dispatch(updateSuccessAction(successMessage));

      dispatch(updateIsLoadingAction(false));
    } catch (errors) {
      dispatch(updateErrorAction(true));
      dispatch(updateIsLoadingAction(false));
    }
  };

  return (
    <div className="UploadButton">
      <div className="UploadButton__Button">
        <FieldPopover
          label={label}
          className="UploadButton__PopoverField"
          component={Button}
          onClose={onClosePopover}
          componentProps={{
            color: 'primary',
            className: 'UploadButton__PopoverField__Button',
          }}
          popoverProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }}
        >
          <UploadForm
            {...state}
            onCsvFileSelect={onCsvFileSelect}
            onUploadClick={onUploadClick}
            onTryAgain={onTryAgain}
            formComponent={children}
            validFileTypes={validFileTypes}
          />
        </FieldPopover>
      </div>
    </div>
  );
};

UploadButton.defaultProps = {
  children: null,
  label: 'Upload',
  validFileTypes: '.csv',
};

UploadButton.propTypes = {
  onUpload: PropTypes.func.isRequired,
  children: PropTypes.node,
  label: PropTypes.string,
  validFileTypes: PropTypes.string,
};

export default UploadButton;
