import React from 'react';

const ExclaimIconRed = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        display: 'inline-block',
        marginRight: '0.25rem',
        verticalAlign: 'middle',
      }}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-472 -583)">
          <path stroke="#E01F1F" d="M.5 183.5h1367v1442H.5z" />
        </g>
        <g transform="translate(-452 -248)">
          <path stroke="#E01F1F" d="M.5.5h1195v463H.5z" />
        </g>
        <path d="M0 0h24v24H0" />
        <path
          d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
          fill="#E01F1F"
        />
      </g>
    </svg>
  );
};

export default ExclaimIconRed;
