import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import LoadingModal from '../LoadingModal';
import ExclaimIconRed from '../Svg/ExclaimIconRed';
import GreenCheck from '../Svg/GreenCheck';

const UploadForm = ({
  error,
  isLoading,
  success,
  onCsvFileSelect,
  onTryAgain,
  onClose,
  onUploadClick,
  csvFile,
  formComponent,
  validFileTypes,
}) => {
  return (
    <div className="UploadButton__Content">
      {!error && !isLoading && !success && (
        <div>
          <div className="UploadButton__FileUploadLabel">
            SELECT FILE FOR UPLOAD
          </div>
          <input
            data-testid="UploadButton__FileUpload"
            className="UploadButton__FileUpload"
            type="file"
            onChange={onCsvFileSelect}
            accept={validFileTypes}
          />
          <div className="UploadButton__Filetypes">
            File Types: {validFileTypes}
          </div>
          {formComponent}
        </div>
      )}
      <LoadingModal open={isLoading} modalText="Processing..." />
      {error && (
        <div className="UploadButton__ErrorSection">
          <div className="UploadButton__ExclamationError">
            <ExclaimIconRed />
            <span className="UploadButton__ExclamationErrorMessage">
              An error occurred
            </span>
          </div>
          <div>Please make sure your file is formatted correctly.</div>
        </div>
      )}
      {success && (
        <div
          data-testid="UploadButton__SuccessSection"
          className="UploadButton__SuccessSection"
        >
          <GreenCheck />
          {success}
        </div>
      )}
      <div>
        {error && (
          <Button
            className="UploadButton__TryAgainButton"
            data-testid="UploadButton__TryAgainButton"
            onClick={onTryAgain}
            variant="primary"
          >
            TryAgain
          </Button>
        )}
        {!error && !isLoading && !success && (
          <div className="UploadButton__Buttons">
            <Button
              variant="secondary"
              className="UploadButton__CancelButton"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="UploadButton__UploadButton"
              data-testid="UploadButton__UploadButton"
              onClick={onUploadClick}
              disabled={!csvFile}
            >
              Upload
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

UploadForm.defaultProps = {
  csvFile: null,
  success: '',
  onClose: () => {},
  formComponent: null,
  validFileTypes: '.csv',
};

UploadForm.propTypes = {
  error: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.string,
  csvFile: PropTypes.any,
  onCsvFileSelect: PropTypes.func.isRequired,
  onTryAgain: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onUploadClick: PropTypes.func.isRequired,
  formComponent: PropTypes.node,
  validFileTypes: PropTypes.string,
};

export default UploadForm;
