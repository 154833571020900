import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@mui/material';
import TooltipLabel from '../TooltipLabel';

import './FileField.scss';

const FileField = ({
  label,
  tooltip,
  required,
  disabled,
  helperText,
  multiple,
  onChange,
  ...otherProps
}) => {
  const onFileChange = ({ target: { files } }) => {
    const value = multiple ? files : files[0];

    onChange({ target: { value } });
  };

  return (
    <FormControl variant="standard" className="MRets__FileField">
      {label && (
        <h5 className="MRets__FileField__Label f5 lh-copy dark-gray fw7 mb1">
          {!tooltip && label}
          {!!tooltip && <TooltipLabel tooltip={tooltip} label={label} />}
          {required && <span className="MRets__FileField__Required"> *</span>}
        </h5>
      )}
      <div className="MRets__FileField__FileInputContainer">
        <input
          className="MRets__FileField__FileInput"
          type="file"
          onChange={onFileChange}
          disabled={disabled}
          multiple={multiple}
          {...otherProps}
        />
      </div>

      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

FileField.defaultProps = {
  disabled: false,
  required: false,
  multiple: false,
  helperText: undefined,
  label: undefined,
  tooltip: undefined,
};

FileField.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default FileField;
