import React from 'react';
import PropTypes from 'prop-types';
import SelectField from '../SelectField';
import { COUNTRY_STATE_CONFIG } from './CountryState.constants';
import { getCountryByCode, getStateByCode } from '../../../utils/helpers';

const CountryState = ({
  countries,
  states,
  selectedCountry,
  selectedState,
  onCountryChange,
  onStateChange,
  updateCountryStates,
  returnLabels,
  returnStateLabels,
  countryConfig,
  stateConfig,
}) => {
  const countryProps = {
    ...COUNTRY_STATE_CONFIG.country,
    ...countryConfig,
  };

  const stateProps = {
    ...COUNTRY_STATE_CONFIG.state,
    ...stateConfig,
  };

  const onCountryFieldChange = (event) => {
    let { value } = event.target;

    if (returnLabels) {
      const countryObj = getCountryByCode(value);
      value = countryObj ? countryObj.label : value;
    }

    onCountryChange({ target: { value } });

    updateCountryStates(event.target.value);
  };

  const onStateFieldChange = (event) => {
    let { value } = event.target;

    if (returnStateLabels) {
      const stateObj = getStateByCode(selectedCountry, value);
      value = stateObj ? stateObj.label : value;
    }

    onStateChange({ target: { value } });
  };

  return (
    <React.Fragment>
      <SelectField
        options={countries}
        value={selectedCountry || ''}
        onChange={onCountryFieldChange}
        {...countryProps}
      />
      {(!selectedCountry || !!states.length) && (
        <SelectField
          options={states}
          value={selectedState || ''}
          onChange={onStateFieldChange}
          {...stateProps}
        />
      )}
    </React.Fragment>
  );
};

CountryState.defaultProps = {
  returnLabels: false,
  returnStateLabels: false,
  countries: [],
  states: [],
  selectedCountry: null,
  selectedState: null,
  countryConfig: {},
  stateConfig: {},
};

CountryState.propTypes = {
  returnLabels: PropTypes.bool,
  returnStateLabels: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.object),
  states: PropTypes.arrayOf(PropTypes.object),
  selectedCountry: PropTypes.string,
  selectedState: PropTypes.string,
  countryConfig: PropTypes.object,
  stateConfig: PropTypes.object,
  onCountryChange: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
  updateCountryStates: PropTypes.func.isRequired,
};

export default CountryState;
